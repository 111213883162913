import Keycloak from 'keycloak-js'
import { config } from '../types/config'

export const keycloakInitConfig: Keycloak.KeycloakInitOptions = {
  onLoad: 'check-sso'
}

export const keycloakEventLogger = (event: unknown, error: unknown): void => {
  console.log('onKeycloakEvent', event, error)
}

// keycloakTokenLogger(tokens: unknown)
export const keycloakTokenLogger = (): void => {
  console.log('onKeycloakTokens')
}

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak(config.keycloak)

export default keycloak
