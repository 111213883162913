import styled from 'styled-components'

export const InputContainer = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: column;

  margin: 30px 0;

  input {
    padding: 16px 14px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    outline: none;

    font-weight: 400;
    font-size: ${({ theme }) => theme.globalStyle.font.fontSize};
    font-family: ${({ theme }) => theme.globalStyle.font.fontFamily};
    text-align: ${({ theme }) => theme.globalStyle.font.fontAlign};

    ${props => props.error && 'border-color: #f44336 !important'};

    &::placeholder {
      opacity: 0.5;
    }

    &:hover {
      border: 1px solid #333;
    }
    &:focus {
      border: 1px solid #000;
    }
  }

  label {
    text-transform: capitalize;

    color: #2c2c2c;

    ${props => props.error && 'color: #f44336 !important'};

    font-size: ${({ theme }) => theme.globalStyle.font.fontSize};
    font-family: ${({ theme }) => theme.globalStyle.font.fontFamily};
    text-align: ${({ theme }) => theme.globalStyle.font.fontAlign};
    font-weight: 500;
    font-style: normal;
  }

  p {
    ${props => props.error && 'color: #f44336 !important'};

    margin-top: 3px;

    font-size: ${({ theme }) => theme.globalStyle.font.fontSize};
    font-family: ${({ theme }) => theme.globalStyle.font.fontFamily};
    text-align: ${({ theme }) => theme.globalStyle.font.fontAlign};

    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
`

export const SearchInputContainer = styled(InputContainer)`
  margin: 0;
  input {
    font-size: 1rem;
    padding: 8px;
  }
`
