import React from 'react'
import { Card, ExpandContainer, HeaderActions } from './styles'
import { SubTitle } from '../styles'
import { ConfigurationMessage } from '../../types'
import { FaArrowsAltH } from 'react-icons/fa'

type Props = {
  config: ConfigurationMessage
  collapsed?: boolean
  hideCollapseBtn?: boolean
  collapse(): void
}

const MessageCard: React.FC<Props> = ({
  config,
  collapse,
  collapsed,
  hideCollapseBtn
}) => {
  return (
    <Card color="#fcf2d2" collapsed={collapsed}>
      {!hideCollapseBtn && (
        <HeaderActions>
          <ExpandContainer onClick={collapse}>
            <FaArrowsAltH />
          </ExpandContainer>
        </HeaderActions>
      )}
      <SubTitle>{config.title}</SubTitle>
      {config.content && (
        <div dangerouslySetInnerHTML={{ __html: config.content }} />
      )}
    </Card>
  )
}

export default MessageCard
