import React from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  HttpLink,
  ApolloLink
} from '@apollo/client'
import { useKeycloak } from '@react-keycloak/web'
import { WebSocketLink } from '@apollo/client/link/ws'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { toast } from 'react-toastify'
import { config } from '../types/config'

interface Definitions {
  kind: string
  operation?: string
}

export const ApolloConnectProvider: React.FC<any> = ({ children }) => {
  const { keycloak } = useKeycloak()
  const token = keycloak?.token

  const wsLink = new WebSocketLink({
    uri: config.ws,
    options: {
      reconnect: true,
      connectionParams: {
        authorization: token ? `Bearer ${token}` : undefined
      }
    }
  })

  const httpLink = new HttpLink({
    uri: config.uri,
    headers: {
      authorization: `Bearer ${token}`
    }
  })

  const link = split(
    ({ query }) => {
      const { kind, operation }: Definitions = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    httpLink
  )

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, extensions }) => {
        if (extensions && extensions.code === 'E_UNAUTHORIZED') {
          setTimeout(() => {
            if (keycloak) {
              return keycloak?.logout()
            }
          }, 1500)
        }
        if (extensions && extensions.code === 'WITHOUT_PARTICIPANT') {
          setTimeout(() => {
            if (keycloak) {
              return keycloak?.logout()
            }
          }, 1500)
        }
        return toast.error(message)
      })
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
    }
  })

  console.log('CONNECT_APOLLO')

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, link])
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
