import baseStyled, { ThemedStyledInterface } from 'styled-components'

export const breakpoints = {
  XS: '319px',
  S: '424px',
  M: '767px',
  L: '1023px'
}

export const space = {
  NONE: 0,
  XS: 2,
  S: 4,
  M: 8,
  L: 16,
  XL: 32,
  XXL: 64
}

export const theme = {
  space: {
    ...space
  },
  breakpoints,
  assets: {
    backgroundImage: null,
    logo: null
  },
  globalStyle: {
    font: {
      fontSize: '1.5rem',
      fontFamily: 'Roboto',
      fontAlign: 'left'
    },
    colors: {
      primary: '#bb141a',
      secondary: '#ED1C24',
      textColor: '#f9f9f9',
      backgroundColor: '#f9f9f9'
    }
  },
  practiceStyle: {
    font: {
      fontSize: '1rem',
      fontFamily: 'Roboto',
      fontAlign: 'left'
    },
    colors: {
      primary: '#bb141a',
      secondary: '#ED1C24',
      textColor: '#2C2C2C',
      backgroundColor: '#E0E0E0'
    }
  },
  categoryStyle: {
    font: {
      fontSize: '1rem',
      fontFamily: 'Roboto',
      fontAlign: 'left'
    },
    colors: {
      primary: '#bb141a',
      secondary: '#ED1C24',
      textColor: '#2C2C2C',
      backgroundColor: '#E0E0E0'
    }
  }
}

export type Theme = typeof theme
export const styled = baseStyled as ThemedStyledInterface<Theme>
