import React from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { AppRouter } from './routes'
import { theme } from './styles/theme'
import { GlobalStyle } from './styles/global'
import keycloak, {
  keycloakEventLogger,
  keycloakInitConfig,
  keycloakTokenLogger
} from './providers/keycloak'
import { ApolloConnectProvider } from './providers'

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={keycloakInitConfig}
        onEvent={keycloakEventLogger}
        onTokens={keycloakTokenLogger}
      >
        <ApolloConnectProvider>
          <AppRouter />
          <ToastContainer />
        </ApolloConnectProvider>
      </ReactKeycloakProvider>
    </ThemeProvider>
  )
}

export default App
