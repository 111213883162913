import React from 'react'
import { ParticipantConnectionEnum } from '../../types'
import { FaQuestionCircle, FaSignal, FaTimesCircle } from 'react-icons/all'
import styled from 'styled-components'

const IconConection = styled.div`
  //background: #fff;
  //border-radius: 50%;
  //width: 24px;
  //height: 24px;
  //line-height: 24px;
  //text-align: center;
  //font-size: 14px;
  //cursor: pointer;
  //display: flex;
  //justify-content: center;
  //align-items: center;
`

type Props = {
  connection: ParticipantConnectionEnum
}
const ConnectionIcon: React.FC<Props> = ({ connection }) => {
  const handleIconConnection = () => {
    switch (connection) {
      case ParticipantConnectionEnum.Online:
        return <FaSignal style={{ color: '#86ff0e' }} />
      case ParticipantConnectionEnum.Offline:
        return <FaTimesCircle style={{ color: '#f7052e' }} />
      case ParticipantConnectionEnum.Away:
        return (
          <FaQuestionCircle
            className="fas fa-question-circle"
            style={{ color: '#ffe603' }}
          />
        )
    }
  }

  return (
    <IconConection
      aria-haspopup="true"
      aria-controls="av-menu"
      aria-label="icon conection"
    >
      {handleIconConnection()}
    </IconConection>
  )
}
export default ConnectionIcon
