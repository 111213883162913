import React from 'react'
import { Category, Maybe, Practice } from '../../types'
import {
  Card,
  CategoryTitle,
  ExpandContainer,
  HeaderActions,
  PracticeTitle
} from './styles'
import { FaArrowsAltH } from 'react-icons/fa'

type Props = {
  category: Category
  result: Maybe<Array<Maybe<Practice>>> | undefined
  collapsed?: boolean
  collapse(): void
  hideCollapseBtn?: boolean
}

const ResultCategory: React.FC<Props> = ({
  category,
  result,
  collapsed,
  hideCollapseBtn,
  collapse
}) => {
  const practices = (result || []) as unknown as Practice[]

  return (
    <Card color="#E9E9E9" collapsed={collapsed}>
      {!hideCollapseBtn && (
        <HeaderActions>
          <ExpandContainer onClick={collapse}>
            <FaArrowsAltH />
          </ExpandContainer>
        </HeaderActions>
      )}
      <div style={{ overflow: 'auto' }}>
        <CategoryTitle>{category.name}</CategoryTitle>
        <br />
        {practices.map(p => (
          <div
            style={{ padding: '10px 50px', borderBottom: '1px solid #e1e1e1' }}
            key={p._id}
          >
            <PracticeTitle>
              {p.number} - {p.name}
            </PracticeTitle>
          </div>
        ))}
      </div>
    </Card>
  )
}

export default ResultCategory
