import React from 'react'
import Layout from '../components/Layout'
import { Title, Container, SubTitle } from '../components/styles'
import { ButtonOutline, ButtonSolid } from '../components/Buttons/styles'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { SET_PARTICIPANT_ME_AV } from '../components/auth/participantMe'
import { ParticipantAVEnum } from '../types'

const CenteredContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: -73px;
  padding: 16px;

  @media (max-width: 800px) {
    padding: 16px;
  }
`

const SelecModeStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-width: 80vw;
  margin-top: 3rem;
  gap: 16px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const CenteredCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem 0;

  span {
    padding-bottom: 1rem;
  }
`

const SelectModePage: React.FC = () => {
  const history = useHistory()
  const [updateParticipant] = useMutation(SET_PARTICIPANT_ME_AV)

  const setMode = (mode: ParticipantAVEnum) => {
    updateParticipant({
      variables: {
        av: mode
      }
    }).then(() => {
      history.push('/vote')
    })
  }
  return (
    <Layout>
      <CenteredContainer>
        <Title>Prêmio Innovare</Title>
        <SubTitle>Como você está participando?</SubTitle>

        <SelecModeStyled>
          <CenteredCol>
            <span>
              Presencialmente, desta forma não será carregada a interface de
              comunicação.
            </span>
            <ButtonSolid
              type="button"
              onClick={() => setMode(ParticipantAVEnum.Off)}
            >
              Presencial
            </ButtonSolid>
          </CenteredCol>

          <CenteredCol>
            <span>
              Remotamente, desta forma será carregada a interface de
              comunicação.
            </span>
            <ButtonOutline
              type="button"
              onClick={() => setMode(ParticipantAVEnum.On)}
            >
              Remoto
            </ButtonOutline>
          </CenteredCol>
        </SelecModeStyled>
      </CenteredContainer>
    </Layout>
  )
}

export default SelectModePage
