import React from 'react'
import { Card, ExpandContainer, HeaderActions } from './styles'
import { ConfigurationStreaming } from '../../types'
import { FaArrowsAltH } from 'react-icons/fa'

type Props = {
  config?: ConfigurationStreaming
  collapsed?: boolean
  collapse(): void
}

const Streaming: React.FC<Props> = ({ config, collapsed, collapse }) => {
  return (
    <Card color="#E9E9E9" collapsed={collapsed}>
      <HeaderActions right>
        <ExpandContainer onClick={collapse}>
          <FaArrowsAltH />
        </ExpandContainer>
      </HeaderActions>
      {config?.iframe ? (
        <div
          dangerouslySetInnerHTML={{
            __html: `<iframe src="${config.iframe}" />`
          }}
        />
      ) : (
        config?.embed && (
          <div dangerouslySetInnerHTML={{ __html: config.embed }} />
        )
      )}
    </Card>
  )
}

export default Streaming
