import React, { useCallback, useContext, useEffect, useState } from 'react'

import {
  CategoryStatusEnum,
  ConfigurationScreenTypeEnum,
  ConfigurationSignUpAuthTypeEnum,
  Participant,
  ParticipantAVEnum,
  ParticipantRoleEnum,
  ParticipantStatusEnum
} from '../types'
import { ConfigContext } from '../providers'
import {
  Container,
  Empty,
  Layout,
  MessageCard,
  ResultCategory,
  ResultCover,
  Streaming,
  VotingCard
} from '../components'
import { CollapsedDiv, ContainerFlex } from '../components/Vote/styles'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { PARTICIPANT_ME } from '../components/auth/participantMe'
import { BsCameraVideoFill } from 'react-icons/bs'
import { FaVoteYea } from 'react-icons/fa'
import ChangeModeBtn from '../components/Buttons/ChangeModeBtn'
import GodUserCard from '../components/Vote/god-user-card'

type CollapseProp = {
  collapsed: boolean
}

const VotePage: React.FC = () => {
  const { data: participantMe } = useQuery<{ participantMe: Participant }>(
    PARTICIPANT_ME
  )
  const [screen, setScreen] = useState<ConfigurationScreenTypeEnum>(
    ConfigurationScreenTypeEnum.Empty
  )
  const [collapse, setCollapse] = useState<{
    vote: boolean
    streaming: boolean
  }>({
    streaming: false,
    vote: false
  })
  const [selectedVoter, setSelectedVoter] = useState<Participant | null>(null)
  const configContext = useContext(ConfigContext)
  const history = useHistory()

  let streamCpt: any
  let screenCpt

  const handleWaitCase = useCallback(() => {
    history.push('/vote/aguarde')
  }, [history])

  const handleNotWaitCase = useCallback(() => {
    history.push('/vote')
  }, [history])

  const handleNeedSelectModeCase = useCallback(() => {
    history.push('/selecionar-modo')
  }, [history])

  const handleCollapseVote = () => {
    console.log('Expand vote')
    setCollapse({
      ...collapse,
      streaming: !collapse.streaming
    })
  }

  const handleCollapseStreaming = () => {
    console.log('Expand stream')
    setCollapse({
      ...collapse,
      vote: !collapse.vote
    })
  }

  useEffect(() => {
    if (
      configContext.signUp?.authType ===
        ConfigurationSignUpAuthTypeEnum.Private &&
      participantMe?.participantMe.status === ParticipantStatusEnum.Pending
    ) {
      handleWaitCase()
    } else if (
      configContext.streaming?.enable &&
      !sessionStorage.getItem('innovareMode') &&
      participantMe?.participantMe.av === ParticipantAVEnum.Pending
    ) {
      handleNeedSelectModeCase()
    } else {
      handleNotWaitCase()
    }

    if (configContext?.screen?.screenType) {
      setScreen(configContext?.screen?.screenType)
    }
  }, [configContext, handleWaitCase, handleNotWaitCase, participantMe])

  useEffect(() => {
    if (screen === ConfigurationScreenTypeEnum.Message) {
      setCollapse({
        vote: false,
        streaming: false
      })
    }
  }, [screen, configContext.messageCurrent])

  if (
    configContext?.streaming &&
    configContext?.streaming.enable &&
    (participantMe?.participantMe.av === ParticipantAVEnum.On ||
      participantMe?.participantMe.av === ParticipantAVEnum.Ignored)
  ) {
    streamCpt = (
      <Streaming
        config={configContext?.streaming}
        collapsed={collapse.streaming}
        collapse={handleCollapseStreaming}
      />
    )
  }

  const hiddeCollapseAVButton: boolean =
    participantMe?.participantMe.av === ParticipantAVEnum.Off ||
    !configContext.streaming?.enable ||
    participantMe?.participantMe.role === ParticipantRoleEnum.God

  if (
    screen === ConfigurationScreenTypeEnum.Voting &&
    configContext?.screen?.category
  ) {
    screenCpt = (
      <VotingCard
        collapse={handleCollapseVote}
        collapsed={collapse.vote}
        category={configContext.screen.category}
        participant={participantMe && participantMe.participantMe}
        voter={
          participantMe?.participantMe.role === ParticipantRoleEnum.God &&
          selectedVoter
            ? selectedVoter
            : undefined
        }
        hideCollapseBtn={hiddeCollapseAVButton}
      />
    )
  }

  if (
    screen === ConfigurationScreenTypeEnum.Message &&
    configContext.messageCurrent
  ) {
    screenCpt = (
      <MessageCard
        collapse={handleCollapseVote}
        collapsed={collapse.vote}
        config={configContext.messageCurrent}
        hideCollapseBtn={hiddeCollapseAVButton}
      />
    )
  }

  if (
    screen === ConfigurationScreenTypeEnum.Cover &&
    configContext.screen?.category
  ) {
    screenCpt = (
      <ResultCover
        collapse={handleCollapseVote}
        collapsed={collapse.vote}
        category={configContext.screen.category}
        hideCollapseBtn={hiddeCollapseAVButton}
      />
    )
  }

  if (
    screen === ConfigurationScreenTypeEnum.Result &&
    configContext.screen?.category
  ) {
    screenCpt = (
      <ResultCategory
        collapse={handleCollapseVote}
        collapsed={collapse.vote}
        category={configContext.screen.category}
        result={configContext.screen?.results || undefined}
        hideCollapseBtn={hiddeCollapseAVButton}
      />
    )
  }

  if (screen === ConfigurationScreenTypeEnum.Empty) {
    screenCpt = (
      <Empty
        collapse={handleCollapseVote}
        collapsed={collapse.vote}
        hideCollapseBtn={hiddeCollapseAVButton}
      />
    )
  }

  const CollapsedStreaming = ({ collapsed }: CollapseProp) => (
    <CollapsedDiv collapsed={collapsed}>
      <div>
        <BsCameraVideoFill
          style={{ fontSize: '3rem', cursor: 'pointer' }}
          onClick={handleCollapseVote}
        />
      </div>
    </CollapsedDiv>
  )

  const getColorCard = () => {
    switch (configContext.screen?.category?.status) {
      case CategoryStatusEnum.Voting:
        return '#d3efdf'
      case CategoryStatusEnum.Voted:
        return '#fcd2d3'
      case CategoryStatusEnum.Pendent:
      default:
        return '#fff'
    }
  }

  const CollapsedVote = ({ collapsed }: CollapseProp) => (
    <CollapsedDiv
      collapsed={collapsed}
      style={{ backgroundColor: getColorCard() }}
    >
      <div>
        <FaVoteYea
          style={{ fontSize: '3rem', cursor: 'pointer' }}
          onClick={handleCollapseStreaming}
        />
      </div>
    </CollapsedDiv>
  )

  const enableChangeMode = !!(
    configContext.streaming?.enable &&
    participantMe?.participantMe.av !== ParticipantAVEnum.Ignored
  )

  const streamingOrSuperUser = () => {
    if (participantMe?.participantMe.role === ParticipantRoleEnum.God) {
      return (
        <GodUserCard
          config={configContext}
          collapsed={false}
          onVoterChange={voter => setSelectedVoter(voter)}
          collapse={handleCollapseStreaming}
        />
      )
    }

    return (
      <>
        <CollapsedStreaming collapsed={collapse.streaming} />
        {streamCpt}
      </>
    )
  }

  return (
    <Layout>
      <Container>
        <ChangeModeBtn show={enableChangeMode} />
        <ContainerFlex>
          {streamingOrSuperUser()}

          <CollapsedVote collapsed={collapse.vote} />
          {screenCpt}
        </ContainerFlex>
      </Container>
    </Layout>
  )
}

export default VotePage
