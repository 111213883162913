import styled from 'styled-components'

export const LayoutContainer = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  background-image: url(${({ theme }) => theme.assets.backgroundImage});
  background-size: cover;
  background-color: ${({ theme }) => theme.globalStyle.colors.backgroundColor};
`
export const Header = styled.div`
  width: 100%;
  padding: 10px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`
