import styled from 'styled-components'

export const Title = styled.h1`
  font-size: 96px;
  text-shadow: 0px 2px 5px ${({ theme }) => theme.globalStyle.colors.primary};
  color: ${({ theme }) => theme.globalStyle.colors.primary};
  text-align: center;
`

export const TitleSM = styled.h1`
  font-weight: bold;
  text-align: center;
  /* padding-top: 150px; */

  color: ${({ theme }) => theme.globalStyle.colors.secondary};
  text-shadow: 0px 2px 5px ${({ theme }) => theme.globalStyle.colors.primary};
  font-size: clamp(42px, 42px + 1vw, 96px);
  //@media only screen and (min-height: 670px) {
  //  font-size: 62px;
  //}
  //@media only screen and (min-height: 770px) {
  //  font-size: 72px;
  //}
  //@media only screen and (min-height: 900px) {
  //  font-size: 96px;
  //}
`
export const SubTitle = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  margin-top: 24px;
  color: #000000;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`
export const ContainerColumnCenter100vh = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

export const LogoBox = styled.div`
  > img {
    max-height: 55px;
  }
`

export const LogoutBtn = styled.button`
  background-color: ${({ theme }) => theme.globalStyle.colors.primary};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  color: ${({ theme }) => theme.globalStyle.colors.textColor};
  font-size: 18px;

  padding: 14px 40px;
  border-radius: 5px;

  outline: none;
  border: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.globalStyle.colors.secondary};
  }

  > svg {
    margin-right: 5px;
  }
`

export const AppVersion = styled.div`
  position: absolute;
  bottom: 1px;
  right: 1px;
  font-size: 10px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ParticipantMe = styled.div`
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%);

  padding: 14px 40px;

  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.globalStyle.colors.primary};

  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    margin-right: 5px;
  }
`

export const SplashScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;

  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    padding-bottom: 100px;
  }
`
