import { useMutation } from '@apollo/client'
import { useKeycloak } from '@react-keycloak/web'
import React, { ChangeEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  ContainerColumnCenter100vh,
  InputField,
  Layout,
  TitleSM
} from '../components'
import { SIGN_UP } from '../components/auth/signupt'
import { ButtonOutline, ButtonSolid } from '../components/Buttons/styles'
import { ParticipantRoleEnum, SignUpInput } from '../types'

const RegisterPage: React.FC = () => {
  const { keycloak } = useKeycloak()
  const [signUp, { loading }] = useMutation(SIGN_UP)
  const [input, setInput] = useState<SignUpInput>({
    login: '',
    name: '',
    password: '',
    passwordConfirmation: '',
    role: ParticipantRoleEnum.Voter
  })
  const history = useHistory()

  function hasErrors() {
    if (input.login === '') {
      return true
    } else if (input.name === '') {
      return true
    } else if (input.password === '') {
      return true
    } else if (input.password !== input.passwordConfirmation) {
      return true
    } else {
      return false
    }
  }

  const register = () => {
    signUp({
      variables: {
        input
      }
    })
      .then(() => {
        toast.success(
          'Usuário criado com sucesso! Aguarde, você será redirecionado à página de acesso automaticamente.',
          {
            autoClose: 4000
          }
        )
        setTimeout(() => {
          return keycloak?.login()
        }, 4000)
      })
      .catch(err => {
        console.log('error')
        console.error(err)
      })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    if (name === 'VIEWER' || name === 'VOTER') {
      setInput(inputs => ({ ...inputs, role: value as ParticipantRoleEnum }))
    } else {
      setInput(inputs => ({ ...inputs, [name]: value }))
    }
  }

  return (
    <Layout>
      <ContainerColumnCenter100vh>
        <TitleSM>Prêmio Innovare</TitleSM>
        <div>
          <InputField
            name="login"
            label="Usuário"
            placeholder="Usuário de acesso"
            value={input.login}
            onChange={e => handleChange(e)}
            required
          />
          <InputField
            name="name"
            label="Nome Completo"
            placeholder="Nome Completo"
            value={input.name}
            onChange={e => handleChange(e)}
            required
          />
          <InputField
            name="password"
            label="Senha"
            type="password"
            placeholder="Senha de Acesso"
            value={input.password}
            required
            onChange={e => handleChange(e)}
          />
          <InputField
            name="passwordConfirmation"
            label="Repetir Senha"
            type="password"
            placeholder="Confirmar Senha"
            onChange={e => handleChange(e)}
            value={input.passwordConfirmation}
            required
          />
          <div>
            <InputField
              value={ParticipantRoleEnum.Voter}
              name="VOTER"
              label="Votante"
              type="radio"
              onChange={e => handleChange(e)}
              checked={input.role === ParticipantRoleEnum.Voter}
            />
            <InputField
              onChange={e => handleChange(e)}
              value={ParticipantRoleEnum.Viewer}
              name="VIEWER"
              label="Espectador"
              type="radio"
              checked={input.role === ParticipantRoleEnum.Viewer}
            />
          </div>
          <ButtonSolid
            type="button"
            onClick={() => register()}
            disabled={loading || hasErrors()}
          >
            Confirmar
          </ButtonSolid>
          <ButtonOutline type="button" onClick={() => history.goBack()}>
            Voltar
          </ButtonOutline>
        </div>
      </ContainerColumnCenter100vh>
    </Layout>
  )
}

export default RegisterPage
