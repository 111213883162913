import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  CenterHeader,
  PracticeTitle,
  CardDiv,
  DropdownAv,
  DropdownAvItem,
  ParticipantsTable,
  BoxSearch
} from './styles'

import {
  Participant,
  ParticipantAVEnum,
  ParticipantRoleEnum,
  ParticipantStatusEnum
} from '../../types'
import { AppConfiguration } from '../../providers'
import { ButtonOutline, ButtonReaload } from '../Buttons/styles'
import SelectedVoterCard from './SelectedVoterCard'
import {
  RESULT_PARTICIPANT,
  SET_PARTICIPANT_AV,
  SUBSCRIPTION_RESULT_PARTICIPANT,
  SUBSCRIPTION_NEW_VOTE
} from './graphql'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { AvIcon, SearchInputField } from '../index'
import {
  FaCheckDouble,
  FaFrownOpen,
  FaHandPointRight,
  FaSyncAlt
} from 'react-icons/all'
import ConnectionIcon from './connection-icon'

type Props = {
  config?: AppConfiguration
  collapsed?: boolean
  collapse(): void
  onVoterChange: (voter: Participant) => void
}

const GodUserCard: React.FC<Props> = ({ config, collapsed, onVoterChange }) => {
  const [sltdParticipant, setSltdParticipant] = useState<Participant>()
  const [filteredList, setFilteredList] = useState<Participant[]>([])
  const [participants, setParticipants] = useState<Participant[]>([])
  const [showCard, setShowCard] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')

  const defaultVariables = {
    category: config?.screen?.category?._id,
    params: {
      sort: {
        name: 1
      },
      filter: {
        status: 'APPROVED'
      }
    }
  }

  const { refetch } = useQuery(RESULT_PARTICIPANT, {
    variables: { ...defaultVariables },
    fetchPolicy: 'network-only',
    onCompleted: ({ participants }) => {
      if (participants?.edges) {
        setParticipants([...participants.edges])
      }
    }
  })

  useSubscription(SUBSCRIPTION_RESULT_PARTICIPANT, {
    variables: defaultVariables,
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData?.data?.participantOnChanges?.data) {
        // updateParticipantBySub(subscriptionData.data.participantOnChanges.data, true)
        refetch({ ...defaultVariables })
      }
    }
  })

  useSubscription(SUBSCRIPTION_NEW_VOTE, {
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData?.data?.onNewVote) {
        const vote = subscriptionData?.data?.onNewVote
        console.log('NEW+VOTE', vote)
        updateParticipantBySub({
          _id: vote.participant,
          keypad: vote.participantKeypad,
          votedPractices: vote.votedPractices
        })
      }
    }
  })

  const [updateParticipant] = useMutation(SET_PARTICIPANT_AV)

  useEffect(() => {
    if (sltdParticipant && participants) {
      const has = participants.find(
        (p: Participant) => p._id === sltdParticipant._id
      )

      if (has) {
        setSltdParticipant(has)
      }
    }
  }, [participants, sltdParticipant])

  const updateParticipantBySub = (
    participantChanged: Partial<Participant>,
    noOverrideVotes?: boolean
  ): void => {
    const newListParticipants: Partial<Participant>[] = [...participants]
    const index = participants.findIndex(
      p => p.keypad === participantChanged.keypad
    )

    if (index !== -1) {
      newListParticipants[index] = {
        ...participants[index],
        ...participantChanged,
        votedPractices: noOverrideVotes? participants[index].votedPractices : participantChanged.votedPractices
      }
    } else {
      newListParticipants.push(participantChanged)
    }
    setParticipants(newListParticipants as Participant[])
  }

  const setParticipant = (item: Participant) => {
    if (!item.votedPractices || item.votedPractices.length === 0) {
      setSltdParticipant(item)
      onVoterChange(item)
      setShowCard(true)
    } else {
      setShowCard(false)
    }
  }

  useEffect(() => {
    if (search && participants) {
      const filtered = participants.filter(item => {
        return (
          item.status === ParticipantStatusEnum.Approved &&
          item.role === ParticipantRoleEnum.Voter &&
          item.name.toLowerCase().includes(search.toLowerCase())
        )
      })
      setFilteredList(filtered)
    } else if (!search && participants) {
      const filtered = participants.filter(item => {
        return (
          item.status === ParticipantStatusEnum.Approved &&
          item.role === ParticipantRoleEnum.Voter
        )
      })
      setFilteredList(filtered)
    } else {
      setFilteredList([])
    }
  }, [participants, search])

  const getVoterStatus = (par: Participant) => {
    if (!par.votedPractices || par.votedPractices.length === 0) {
      return <span>Não votou</span>
    } else {
      return <span>{par.votedPractices.join(', ')}</span>
    }
  }
  const getItemByVote = (par: Participant) => {
    if (!par.votedPractices || par.votedPractices.length === 0) {
      return <FaHandPointRight style={{ color: 'rgba(84, 174, 255, 0.8)' }} />
    } else {
      return <FaCheckDouble style={{ color: '#0b821a' }} />
    }
  }

  const getVotedClass = (par: Participant) => {
    if (!par.votedPractices || par.votedPractices.length === 0) {
      return 'not-voted'
    } else {
      return 'voted'
    }
  }

  const getEmptyState = () => {
    if (!search && !filteredList.length) {
      return (
        <tr>
          <td colSpan={5} align={'center'} valign={'middle'}>
            <FaFrownOpen style={{ fontSize: '1.9rem', color: '#bb141a' }} />
            <br />
            Sem participantes no momento.
          </td>
        </tr>
      )
    } else if (search && !filteredList.length) {
      return (
        <tr>
          <td colSpan={5} align={'center'} valign={'middle'}>
            <FaFrownOpen style={{ fontSize: '1.9rem', color: '#bb141a' }} />
            <br />
            Sem participantes para sua busca.
          </td>
        </tr>
      )
    }
  }

  function updateAv(e: ParticipantAVEnum, item: Participant) {
    updateParticipant({
      variables: {
        id: item._id,
        av: e
      }
    })
  }

  return (
    <CardDiv color="#E9E9E9" collapsed={collapsed}>
      {sltdParticipant && showCard && (
        <>
          <CenterHeader>
            <PracticeTitle>Votando pelo participante:</PracticeTitle>
          </CenterHeader>
          <br />
          <SelectedVoterCard participant={sltdParticipant} />

          <ButtonOutline type="button" onClick={() => setShowCard(false)}>
            Trocar
          </ButtonOutline>
        </>
      )}

      {!showCard && participants && (
        <>
          <CenterHeader>
            <PracticeTitle>Votar pelo participante</PracticeTitle>
          </CenterHeader>
          <br />
          <p color="#E9E9E9">
            Selecione abaixo o participante pelo qual irá votar
          </p>

          <BoxSearch>
            <ButtonReaload onClick={() => refetch(defaultVariables)}>
              <FaSyncAlt />
            </ButtonReaload>
            <SearchInputField
              name="search"
              label=""
              placeholder="Buscar"
              value={search}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
              required
            />
          </BoxSearch>
          <ParticipantsTable>
            <thead>
              <tr>
                <td />
                <td />
                <td>Nome</td>
                <td>Votou</td>
                <td>AV</td>
              </tr>
            </thead>
            <tbody>
              {filteredList.map(item => (
                <tr key={item._id} className={getVotedClass(item)}>
                  <td onClick={() => setParticipant(item)}>
                    {getItemByVote(item)}
                  </td>
                  <td>
                    {' '}
                    <ConnectionIcon connection={item.connection} />
                  </td>
                  <td onClick={() => setParticipant(item)}> {item.name}</td>
                  <td>{getVoterStatus(item)}</td>
                  <td>
                    <DropdownAv>
                      <span>
                        <AvIcon av={item.av} />
                      </span>
                      <div className="dropdown-content">
                        <DropdownAvItem
                          onClick={() => updateAv(ParticipantAVEnum.Off, item)}
                          className={
                            item.av === ParticipantAVEnum.Off ? 'active' : ''
                          }
                        >
                          <AvIcon av={ParticipantAVEnum.Off} /> Presencial
                        </DropdownAvItem>
                        <DropdownAvItem
                          onClick={() => updateAv(ParticipantAVEnum.On, item)}
                          className={
                            item.av === ParticipantAVEnum.On ? 'active' : ''
                          }
                        >
                          <AvIcon av={ParticipantAVEnum.On} /> Remoto
                        </DropdownAvItem>
                      </div>
                    </DropdownAv>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>{getEmptyState()}</tfoot>
          </ParticipantsTable>
        </>
      )}
    </CardDiv>
  )
}
export default GodUserCard
