import React from 'react'
import { HashRouter as Router, Redirect, Route } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'

import { PrivateRoute } from './PrivateRoute'
import { LoginPage, RegisterPage, SelectModePage } from '../pages'
import VoteRouter from './VoteRouter'
import { AppVersion, SplashScreen } from '../components/styles'
import { ReactComponent as LoadingSvg } from '../components/assets/loading.svg'
import logo from '../components/assets/logo.png'

import { version } from '../../package.json'

export const AppRouter: React.FC = () => {
  const { initialized } = useKeycloak()

  if (!initialized) {
    return (
      <SplashScreen>
        <img src={logo} alt="Prêmio Innovare" />
        <div>
          <LoadingSvg />
        </div>
      </SplashScreen>
    )
  }

  return (
    <>
      <Router>
        <Redirect from="/" to="/vote" />
        <PrivateRoute path="/selecionar-modo" component={SelectModePage} />
        <PrivateRoute path="/vote" component={VoteRouter} />
        <Route path="/entrar" exact component={LoginPage} />
        <Route path="/cadastro" exact component={RegisterPage} />
      </Router>
      <AppVersion>{version}</AppVersion>
    </>
  )
}
