import React, { useContext } from 'react'
import Layout from '../components/Layout'
import { Title, Container, SubTitle } from '../components/styles'
import ChangeModeBtn from '../components/Buttons/ChangeModeBtn'
import { useQuery } from '@apollo/client'
import { Participant, ParticipantAVEnum } from '../types'
import { PARTICIPANT_ME } from '../components/auth/participantMe'
import { ConfigContext } from '../providers'

const WaitingPage: React.FC = () => {
  const { data: participantMe } = useQuery<{ participantMe: Participant }>(
    PARTICIPANT_ME
  )
  const configContext = useContext(ConfigContext)

  const enableChangeMode = !!(
    configContext.streaming?.enable &&
    participantMe?.participantMe.av !== ParticipantAVEnum.Ignored
  )

  return (
    <Layout>
      <Container>
        <ChangeModeBtn show={enableChangeMode} />
        <Title>Prêmio Innovare</Title>
        <SubTitle>
          Aguarde um instante,
          <br /> estamos liberando o seu <br /> acesso à plataforma.
        </SubTitle>
      </Container>
    </Layout>
  )
}

export default WaitingPage
