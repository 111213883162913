import { gql } from '@apollo/client'

export const MY_VOTES = gql`
  query votes($category: ID!) {
    myVotes(category: $category) {
      ballot
      category
      votes {
        practice
        award
      }
    }
  }
`

export const ADD_VOTES = gql`
  mutation addVote($input: VoteCreateInput!) {
    createVote(input: $input) {
      message
      data {
        ballot
        category
        votes {
          practice
          award
        }
      }
    }
  }
`
export const ADD_VOTES_GOD = gql`
  mutation CreateVoteModeGod($input: VoteCreateInput!, $participant: ID!) {
    createVoteModeGod(input: $input, participant: $participant) {
      message
      data {
        ballot
        category
        votes {
          practice
          award
        }
      }
    }
  }
`
export const VOTE_COUNTER = gql`
  query VoteCounter($category: ID!) {
    votesCounter(category: $category) {
      count
      participants
      ballots
      progress
      voter
    }
  }
`
export const COUNTER_SUBSCRIPTION = gql`
  subscription ResultParticipantsOnChange($category: ID!) {
    votesCounterOnChanges(category: $category) {
      count
      voter
      participants
      ballots
      progress
    }
  }
`

export const PARTICIPANTS_TO_MODE_GOD = gql`
  query ParticipantsToModeGod {
    participantsToModeGod {
      _id
      name
      keypad
    }
  }
`

export const RESULT_PARTICIPANT = gql`
  query Participants($params: ParticipantSearchInput, $category: ID) {
    participants(params: $params) {
      edges {
        _id
        keypad
        login
        name
        status
        connection
        role
        votedPractices(category: $category)
        av
      }
    }
  }
`
export const SUBSCRIPTION_RESULT_PARTICIPANT = gql`
  subscription ParticipantsOnChange($params: ParticipantSearchInput) {
    participantOnChanges(params: $params) {
      _id
      action
      data {
        _id
        keypad
        login
        name
        status
        role
        connection
        av
        votedPractices
      }
    }
  }
`
export const SET_PARTICIPANT_AV = gql`
  mutation SetAvParticipant($id: ID!, $av: ParticipantAvEnum!) {
    setAvParticipant(id: $id, av: $av) {
      message
      data {
        _id
        name
        av
      }
    }
  }
`

export const SUBSCRIPTION_NEW_VOTE = gql`
  subscription newVote {
    onNewVote {
      participant
      participantLogin
      category
      categoryName
      votedPractices
      usernameGod
      participantKeypad
    }
  }
`
