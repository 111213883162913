import React, { createContext, useEffect, useState } from 'react'
import { gql, useQuery, useSubscription } from '@apollo/client'
import { ConfigurationMap, ConfigurationMapOnChanges } from '../types'

const FRAGMENT_STYLE = gql`
  fragment StyleFrag on ConfigurationAppearanceStyle {
    colors {
      backgroundColor
      primary
      secondary
      textColor
    }
    font {
      fontAlign
      fontFamily
      fontSize
    }
  }
`

const FRAGMENT_CONFIG = gql`
  fragment ConfigFrag on ConfigurationMap {
    screen {
      screenType
      category {
        _id
        number
        name
        status
        practices {
          _id
          name
          number
        }
        awards {
          _id
          name
        }
      }
      results {
        name
        number
      }
    }
    messageCurrent {
      title
      content
    }
    streaming {
      enable
      embed
      iframe
    }
    signUp {
      authType
    }
    appearance {
      assets {
        backgroundImage
        logo
      }

      globalStyle {
        ...StyleFrag
      }

      practiceStyle {
        ...StyleFrag
      }

      categoryStyle {
        ...StyleFrag
      }
    }
  }
`

const SUBSCRIPTION_CONFIG = gql`
  subscription ConfMapOnChage {
    configurationMapOnChanges {
      key
      data {
        ...ConfigFrag
      }
    }
  }

  ${FRAGMENT_STYLE}
  ${FRAGMENT_CONFIG}
`

const QUERY_CONFIG = gql`
  query GetConfMap {
    configurationMap {
      ...ConfigFrag
    }
  }

  ${FRAGMENT_STYLE}
  ${FRAGMENT_CONFIG}
`

export type AppConfiguration = {
  other?: string
} & ConfigurationMap

export const ConfigContext = createContext<AppConfiguration>({})

export const AppConfigProvider: React.FC = ({ children }) => {
  const [configs, setConfigs] = useState<AppConfiguration>({})
  const { data } = useQuery<{ configurationMap: ConfigurationMap }>(
    QUERY_CONFIG
  )
  const { data: dataSub } = useSubscription<{
    configurationMapOnChanges: ConfigurationMapOnChanges
  }>(SUBSCRIPTION_CONFIG)

  useEffect(() => {
    if (data) {
      setConfigs(data.configurationMap)
    }
  }, [data])

  useEffect(() => {
    if (dataSub) {
      setConfigs(dataSub.configurationMapOnChanges.data)
    }
  }, [dataSub])

  // console.log('@CONTEXT_CONFIG_UPDATED', configs)

  return (
    <ConfigContext.Provider value={configs}>{children}</ConfigContext.Provider>
  )
}
