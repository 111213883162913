import styled from 'styled-components'

export const ButtonSolid = styled.button`
  display: flex;
  justify-content: center;
  padding: 14px 0;
  margin: 25px 0;

  cursor: pointer;

  width: 100%;
  min-width: 300px;

  position: relative;

  border: 1px solid ${({ theme }) => theme.globalStyle.colors.primary};

  outline: none;

  background: ${({ theme }) => theme.globalStyle.colors.primary};
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  color: ${({ theme }) => theme.globalStyle.colors.textColor};
  font-weight: 500;
  font-size: ${({ theme }) => theme.globalStyle.font.fontSize};
  font-family: ${({ theme }) => theme.globalStyle.font.fontFamily};
  text-align: center;

  &:disabled {
    background: rgba(0, 0, 0, 0.25);
    border: none;
  }

  &:before {
    content: '';
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0%;
  }

  &:hover:before {
    width: 100%;
  }
`

export const ButtonOutline = styled(ButtonSolid)`
  background-color: transparent;
  border-width: 2px;
  color: ${({ theme }) => theme.globalStyle.colors.primary};
  &:hover {
    color: ${({ theme }) => theme.globalStyle.colors.textColor} !important;
    background-color: ${({ theme }) => theme.globalStyle.colors.primary};
  }
`
export const ButtonReaload = styled(ButtonSolid)`
  background-color: transparent;
  border-width: 2px;
  color: ${({ theme }) => theme.globalStyle.colors.primary};

  margin: 0;
  min-width: 40px;
  width: auto;
  padding: 8px;
  font-size: 1rem;
  float: left;
  margin-right: 5px;
  box-shadow: none;

  &:hover {
    color: ${({ theme }) => theme.globalStyle.colors.textColor} !important;
    background-color: ${({ theme }) => theme.globalStyle.colors.primary};
  }
`
