import styled from 'styled-components'

export const Card = styled.div<{ collapsed?: boolean }>`
  background-color: ${props => (props.color ? props.color : '#fff')};

  position: relative;

  margin: 20px;
  padding: 15px;

  width: 100%;
  height: 85vh;

  text-align: center;

  display: ${props => (props.collapsed ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: space-around;
  transition: all 1s ease;
`

export const CollapsedDiv = styled(Card)`
  display: ${props => (props.collapsed ? 'flex' : 'none')};
  width: 10%;
`

export const CategoryTitle = styled.h1`
  font-size: ${({ theme }) => theme.categoryStyle.font.fontSize};
  font-family: ${({ theme }) => theme.categoryStyle.font.fontFamily};
  text-align: ${({ theme }) => theme.categoryStyle.font.fontAlign};

  color: ${({ theme }) => theme.categoryStyle.colors.textColor};
`
export const PracticeTitle = styled.h1`
  font-size: ${({ theme }) => theme.practiceStyle.font.fontSize};
  font-family: ${({ theme }) => theme.practiceStyle.font.fontFamily};
  text-align: ${({ theme }) => theme.practiceStyle.font.fontAlign};

  color: ${({ theme }) => theme.practiceStyle.colors.textColor};
`

export const VotingSection = styled.section`
  padding: 10px 42px 12px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.globalStyle.colors.primary};
  }
`

export const HeaderSection = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media only screen and (min-height: 670px) {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
  }
`

export const HeaderActions = styled(HeaderSection)<{ right?: boolean }>`
  position: absolute;
  grid-template-columns: 1fr;
  top: 35px;
  left: ${props => !props.right && '50px'};
  right: ${props => props.right && '50px'};
`

export const ExpandContainer = styled.div`
  cursor: pointer;
  font-size: 1.5rem;
  &:hover {
    font-size: 2rem;
  }
`

export const ContainerFlex = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
export const ContainerOptions = styled.div`
  width: 100%;

  margin: 10px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const VoteOption = styled.div<{ color?: string; isVoting?: boolean }>`
  pointer-events: ${props => (props.isVoting ? '' : 'none')};

  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const VoteCheckbox = styled.input`
  margin-right: 5px;
  border: 2px solid #333333;
  height: 30px;
  width: 30px;
  cursor: pointer;
`
export const VoteStatusPending = styled.div`
  background: #828282;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  width: auto;
  max-width: 200px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > svg {
    margin-right: 5px;
  }
`
export const VoteLabel = styled.label`
  cursor: pointer;
`

export const VoteStatusOpened = styled(VoteStatusPending)`
  background-color: #27ae60;
`
export const VoteStatusClosed = styled(VoteStatusPending)`
  background-color: #bb141a;
`

export const CardDiv = styled(Card)`
  justify-content: start;
  overflow-y: auto;
  overflow-x: hidden;
`
export const BoxSearch = styled.div`
  padding: 2px 0 8px;
`
export const CenterHeader = styled.div`
  justify-content: center;
  display: flex;
  justify-items: center;
`
export const DropdownAv = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 130px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: -99px;
  }
  &:hover .dropdown-content {
    display: block;
  }
`

export const DropdownAvItem = styled.div`
  font-weight: normal;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 4px;
  row-gap: 4px;
  &.active {
    font-weight: bold;
  }
  &:hover {
    background-color: #ddf4ff;
    cursor: pointer;
  }
`

export const ParticipantsTable = styled.table`
  border: none;
  border-collapse: collapse;
  caption-side: bottom;

  td,
  th {
    border: none;
  }
  td {
    padding: 5px 10px;
    background-color: #fff;
    text-align: left;
  }

  thead tr td {
    font-weight: bold;
  }
  tfoot tr td {
    text-align: center;
  }

  tbody tr {
    td {
      border-bottom: 1px solid #cecece;
    }

    :hover td {
      background-color: #ddf4ff;
      cursor: pointer;
    }
  }
  thead > tr {
    background-color: #fff;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }

  tbody tr.voted {
    td {
      background-color: #d3efdf;
      cursor: auto;
    }
    :hover td {
      background-color: #ddf4ff;
    }
  }
`
