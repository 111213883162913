import React from 'react'
import Layout from '../components/Layout'
import { Title, Container, SubTitle } from '../components/styles'

const BlockedPage: React.FC = () => {
  return (
    <Layout>
      <Container>
        <Title>Prêmio Innovare</Title>
        <SubTitle>
          Acesso bloqueado!
          <br />
          Entre em contato com a organização.
        </SubTitle>
      </Container>
    </Layout>
  )
}

export default BlockedPage
