import React, { useContext } from 'react'
import { Card, ExpandContainer, HeaderActions } from './styles'
import { ConfigContext } from '../../providers'
import { FaArrowsAltH } from 'react-icons/fa'

interface EmptyProps {
  collapse(): void
  collapsed: boolean
  hideCollapseBtn: boolean
}

const Empty: React.FC<EmptyProps> = ({
  collapse,
  collapsed,
  hideCollapseBtn
}) => {
  const configContext = useContext(ConfigContext)

  return (
    <Card color="#E9E9E9" collapsed={collapsed}>
      {!hideCollapseBtn && (
        <HeaderActions>
          <ExpandContainer onClick={collapse}>
            <FaArrowsAltH />
          </ExpandContainer>
        </HeaderActions>
      )}

      {configContext.appearance?.assets?.logo && (
        <div>
          <img
            src={configContext.appearance?.assets?.logo}
            alt="Instituto Innovare Votação"
          />
        </div>
      )}
    </Card>
  )
}

export default Empty
