import React from 'react'
import { ParticipantAVEnum } from '../../types'
import { FaColumns, FaDesktop, FaHourglassHalf } from 'react-icons/all'
import styled from 'styled-components'

const IconAv = styled.div`
  background: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

type Props = {
  av: ParticipantAVEnum
}
const AvIcon: React.FC<Props> = ({ av }) => {
  const handleIconAv = () => {
    switch (av) {
      case ParticipantAVEnum.On:
        return <FaColumns style={{ color: '#0b821a' }} />
      case ParticipantAVEnum.Off:
        return <FaDesktop style={{ color: '#f7052e' }} />
      case ParticipantAVEnum.Pending:
        return <FaHourglassHalf style={{ color: '#ffe603' }} />
      case ParticipantAVEnum.Ignored:
      default:
        return ''
    }
  }

  return (
    <IconAv
      aria-haspopup="true"
      aria-controls="av-menu"
      aria-label="controle av"
    >
      {handleIconAv()}
    </IconAv>
  )
}
export default AvIcon
