import React from 'react'

import styled from 'styled-components'
import { Participant } from '../../types'
import { AvIcon } from '../index'
import ConnectionIcon from './connection-icon'

const StyledCard = styled.div`
  background: #fff9c4;
  box-shadow: -3px -3px 7px #fff9c4, 3px 3px 5px #fff9c4;
  text-align: start;
  padding: 16px;
`
const BoxStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const StatusRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  row-gap: 8px;
`

type Props = {
  participant: Participant
}
const SelectedVoterCard: React.FC<Props> = ({ participant }) => {
  const getVoterStatus = () => {
    if (
      !participant.votedPractices ||
      participant.votedPractices.length === 0
    ) {
      return <b>Não votou</b>
    } else {
      return <b>Voto: {participant.votedPractices.join(' ,')}</b>
    }
  }

  return (
    <div>
      <StyledCard>
        <span>Participante selecionado:</span>
        <br />
        <p>
          Nome: <strong>{participant.name}</strong>
        </p>
        <p>
          Keypad: <strong>{participant.keypad}</strong>
        </p>
        <BoxStatus>
          {getVoterStatus()}
          <StatusRight>
            <ConnectionIcon connection={participant.connection} />
            <AvIcon av={participant.av} />
          </StatusRight>
        </BoxStatus>
      </StyledCard>
    </div>
  )
}
export default SelectedVoterCard
