import { useMutation, useQuery } from '@apollo/client'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ThemeProvider } from 'styled-components'
import {
  PARTICIPANT_ME,
  SUBSCRIPTION_ME
} from '../components/auth/participantMe'
import { SET_CONNECTION } from '../components/helpers/gql'
import WindowFocusHandler from '../components/helpers/useEventListener'
import { ParticipantMe } from '../components/styles'
import { VotePage, WaitingPage } from '../pages'
import {AppConfigProvider, ConfigContext} from '../providers'
import {
  ConfigurationSignUpAuthTypeEnum,
  ParticipantConnectionEnum,
  ParticipantRoleEnum,
  ParticipantStatusEnum
} from '../types'
import { PrivateRoute } from './PrivateRoute'
import { FaCrown, FaEye, FaVoteYea } from 'react-icons/fa'
import BlockedPage from '../pages/Blocked'

const VoteRouter: React.FC = () => {
  const [configTheme, setConfigTheme] = useState({} as any)
  const { data: participantMe, subscribeToMore } = useQuery(PARTICIPANT_ME)
  const [setConnectionParticipantMe] = useMutation(SET_CONNECTION)
  const configContext = useContext(ConfigContext)
  const history = useHistory()

  const handleWaitCase = useCallback(() => {
    history.push('/vote/aguarde')
  }, [history])

  const handleNotWaitCase = useCallback(() => {
    history.push('/vote')
  }, [history])

  const handleBlockedCase = useCallback(() => {
    history.push('/vote/bloqueado')
  }, [history])

  useEffect(() => {
    // console.log('Participant ME: ', participantMe)
    if (
      configContext.signUp?.authType ===
        ConfigurationSignUpAuthTypeEnum.Private &&
      participantMe?.participantMe.status === ParticipantStatusEnum.Pending
    ) {
      handleWaitCase()
    } else if (
      participantMe?.participantMe.status === ParticipantStatusEnum.Blocked
    ) {
      handleBlockedCase()
    } else {
      handleNotWaitCase()
    }
  }, [
    configContext.signUp,
    handleNotWaitCase,
    handleWaitCase,
    participantMe,
    handleBlockedCase
  ])

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: SUBSCRIPTION_ME,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev
        return Object.assign({
          participantMe: subscriptionData.data.participantMeOnChange.data
        })
      },
      onError: error => {
        toast.error(error.message)
      }
    })
    return () => unsubscribe()
  }, [subscribeToMore])

  useEffect(() => {
    if (configContext.appearance) {
      setConfigTheme((theme: any) => ({
        ...theme,
        assets: configContext.appearance?.assets,
        categoryStyle: {
          ...theme.categoryStyle,
          colors: configContext.appearance?.categoryStyle?.colors,
          font: configContext.appearance?.categoryStyle?.font
        },
        practiceStyle: {
          ...theme.practiceStyle,
          colors: configContext.appearance?.practiceStyle?.colors,
          font: configContext.appearance?.practiceStyle?.font
        },
        globalStyle: {
          ...theme.globalStyle,
          colors: configContext.appearance?.globalStyle?.colors,
          font: configContext.appearance?.globalStyle?.font
        }
      }))
    }
  }, [configContext])

  const onFocus = () => {
    setConnectionParticipantMe({
      variables: {
        connection: ParticipantConnectionEnum.Online
      }
    })
  }

  const onBlur = () => {
    setConnectionParticipantMe({
      variables: {
        connection: ParticipantConnectionEnum.Away
      }
    })
  }

  WindowFocusHandler({ onBlur, onFocus })

  function truncateString(str: string, num: number) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  const participantIcon = (role?: ParticipantRoleEnum) => {
    switch (role) {
      case ParticipantRoleEnum.Voter:
        return <FaVoteYea style={{ fontSize: '1.2rem' }} />
      case ParticipantRoleEnum.God:
        return <FaCrown style={{ fontSize: '1.2rem' }} />
      case ParticipantRoleEnum.Viewer:
      default:
        return <FaEye style={{ fontSize: '1.2rem' }} />
    }
  }

  return (
    <>
      <AppConfigProvider>
        <ParticipantMe>
          <span style={{ paddingBottom: 5 }}>
            {participantMe?.participantMe.name &&
              truncateString(participantMe.participantMe.name.split(' ')[0], 15)}
          </span>
          <span>{participantIcon(participantMe?.participantMe.role)}</span>
        </ParticipantMe>
        <ThemeProvider theme={configTheme}>
          <PrivateRoute path="/vote" exact component={VotePage} />
          <PrivateRoute path="/vote/aguarde" exact component={WaitingPage} />
          <PrivateRoute path="/vote/bloqueado" exact component={BlockedPage} />
        </ThemeProvider>
      </AppConfigProvider>
    </>
  )
}

export default VoteRouter
