type Config = {
  uri: string
  ws: string
  keycloak: string
}

export const production: Config = {
  uri:
    process.env.REACT_APP_GRAPHQL_URI ||
    'https://services.votepraticasinnovare.com.br/graphql',
  ws:
    process.env.REACT_APP_GRAPHQL_WS ||
    'wss://services.votepraticasinnovare.com.br/graphql',
  keycloak: process.env.REACT_APP_KEYCLOAK_JSON || '/keycloak.json'
}

export const config = production
