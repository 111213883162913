export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  JSON: any
  Demo: any
  Date: any
  /** MongoDB ObjectId scalar type, sent as 24 byte Hex String */
  ObjectId: any
  Upload: any
}

export enum SortOrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum ActionsEnum {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Deleted = 'DELETED'
}

export type TransObjectInput = {
  en?: Maybe<Scalars['String']>
  es?: Maybe<Scalars['String']>
  pt_br?: Maybe<Scalars['String']>
}

export type TransObject = {
  __typename?: 'TransObject'
  en?: Maybe<Scalars['String']>
  es?: Maybe<Scalars['String']>
  pt_br?: Maybe<Scalars['String']>
}

export type SimpleResponse = {
  __typename?: 'SimpleResponse'
  message: Scalars['String']
}

export type HttpError = {
  __typename?: 'HttpError'
  httpCode?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
}

export type Validation = {
  __typename?: 'Validation'
  message: Scalars['String']
  field: Scalars['String']
  validation: Scalars['String']
}

export type ErrorResponse = {
  __typename?: 'ErrorResponse'
  message: Scalars['String']
  error?: Maybe<HttpError>
  validation?: Maybe<Array<Maybe<Validation>>>
}

export type Pagination = {
  __typename?: 'Pagination'
  total: Scalars['Int']
  page: Scalars['Int']
  perPage: Scalars['Int']
  lastPage: Scalars['Int']
}

export type ImageThumb = {
  __typename?: 'ImageThumb'
  thumb: Scalars['String']
  small: Scalars['String']
  large: Scalars['String']
}

export type CustomDate = {
  __typename?: 'CustomDate'
  /** Integer value representing the number of milliseconds since the Unix Epoch (Jan 1 1970 12AM UTC). */
  timestamp?: Maybe<Scalars['Float']>
  /** Unix timestamp (seconds since the Unix Epoch) */
  unix?: Maybe<Scalars['Float']>
  /** UTC time instead of local time */
  utc?: Maybe<Scalars['String']>
  /** Formats a string to the ISO8601 standard. */
  iso?: Maybe<Scalars['String']>
  /** String matches the RFC 2822 Date time format */
  string?: Maybe<Scalars['String']>
  /** This function returns the real offset from UTC, not the reverse offset */
  timezone?: Maybe<Scalars['String']>
  /**
   * If you know the format of an input string, you can use that to parse a date.
   * Readmore: [momentjs format date](https://momentjs.com/docs/#/parsing/string-format)
   */
  format?: Maybe<Scalars['String']>
}

export type CustomDateTimezoneArgs = {
  offset: Scalars['String']
}

export type CustomDateFormatArgs = {
  format: Scalars['String']
}

export type CustomNumber = {
  __typename?: 'CustomNumber'
  string?: Maybe<Scalars['String']>
  int?: Maybe<Scalars['Int']>
  float?: Maybe<Scalars['Float']>
  fixed?: Maybe<Scalars['String']>
  format?: Maybe<Scalars['String']>
}

export type CustomNumberFixedArgs = {
  digits: Scalars['Int']
}

export type CustomNumberFormatArgs = {
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  digits?: Maybe<Scalars['Int']>
}

export type File = {
  __typename?: 'File'
  id: Scalars['ID']
  path: Scalars['String']
  filename: Scalars['String']
  mimetype: Scalars['String']
  encoding: Scalars['String']
}

export type SignUpInput = {
  name: Scalars['String']
  role: ParticipantRoleEnum
  keypad?: Maybe<Scalars['String']>
  login: Scalars['String']
  password: Scalars['String']
  passwordConfirmation: Scalars['String']
}

export type AuthResponse = {
  __typename?: 'AuthResponse'
  accessToken: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  login: AuthResponse
  signUp: SimpleResponse
  createAward: AwardResponse
  updateAward: AwardResponse
  deleteAward: SimpleResponse
  createCategory: CategoryResponse
  updateCategory: CategoryResponse
  deleteCategory: SimpleResponse
  /** Aparência: determina como os participantes verão a interface gráfica do sistema. */
  setConfigAppearance: ConfigurationResponse
  /**
   * Uma mensagem é uma tela que se sobrepõe ao que está sendo exibido ao participante:
   * - Para exibir uma mensagem para os participants basta enviar um OBJETO
   */
  setConfigMessage: ConfigurationResponse
  /**
   * Determina se será possivel o participante poderá se cadastrar sozinho,
   * consulte a doc de **ConfigurationSignUpAuthTypeEnum** para maioreis informações.
   */
  setConfigSignUp: ConfigurationResponse
  /** Deve ser possível inserir um LINK ou um HTML que será o iframe de comunicação a esquerda da tela do votante. */
  setConfigStreaming: ConfigurationResponse
  /** Muda a visualização de tela */
  setConfigScreen: ConfigurationResponse
  singleUpload: Scalars['String']
  multiUpload: Array<Maybe<Scalars['String']>>
  createMessage: MessageResponse
  updateMessage: MessageResponse
  deleteMessage: SimpleResponse
  createParticipant: ParticipantResponse
  updateParticipant: ParticipantResponse
  setConnectionParticipantMe: ParticipantResponse
  deleteParticipant: SimpleResponse
  bulkUpdateParticipant: SimpleResponse
  createPractice: PracticeResponse
  updatePractice: PracticeResponse
  deletePractice: SimpleResponse
  startAutoResult: SimpleResponse
  stopAutoResult: SimpleResponse
  fakeVotesCounter: SimpleResponse
  createVote: VoteResponse
  openVoting: SimpleResponse
  closeVoting: SimpleResponse
  clearAllVotes: SimpleResponse
}

export type MutationLoginArgs = {
  username: Scalars['String']
  password: Scalars['String']
}

export type MutationSignUpArgs = {
  input: SignUpInput
}

export type MutationCreateAwardArgs = {
  input: AwardCreateInput
}

export type MutationUpdateAwardArgs = {
  id: Scalars['ID']
  input: AwardUpdateInput
}

export type MutationDeleteAwardArgs = {
  ids: Array<Scalars['ID']>
}

export type MutationCreateCategoryArgs = {
  input: CategoryCreateInput
}

export type MutationUpdateCategoryArgs = {
  id: Scalars['ID']
  input: CategoryUpdateInput
}

export type MutationDeleteCategoryArgs = {
  ids: Array<Scalars['ID']>
}

export type MutationSetConfigAppearanceArgs = {
  assets?: Maybe<ConfigurationAppearanceAssetsInput>
  global?: Maybe<ConfigurationAppearanceStyleInput>
  practices?: Maybe<ConfigurationAppearanceStyleInput>
  categories?: Maybe<ConfigurationAppearanceStyleInput>
}

export type MutationSetConfigMessageArgs = {
  input: ConfigurationMessageInput
}

export type MutationSetConfigSignUpArgs = {
  authType: ConfigurationSignUpAuthTypeEnum
}

export type MutationSetConfigStreamingArgs = {
  input: ConfigurationStreamingInput
}

export type MutationSetConfigScreenArgs = {
  screen: ConfigurationScreenTypeEnum
  options?: Maybe<ConfigurationScreenOptionsInput>
}

export type MutationSingleUploadArgs = {
  file: Scalars['Upload']
}

export type MutationMultiUploadArgs = {
  input: MultiUploadInput
}

export type MutationCreateMessageArgs = {
  input: MessageCreateInput
}

export type MutationUpdateMessageArgs = {
  id: Scalars['ID']
  input: MessageUpdateInput
}

export type MutationDeleteMessageArgs = {
  ids: Array<Scalars['ID']>
}

export type MutationCreateParticipantArgs = {
  input: ParticipantCreateInput
}

export type MutationUpdateParticipantArgs = {
  id: Scalars['ID']
  input: ParticipantUpdateInput
}

export type MutationSetConnectionParticipantMeArgs = {
  connection: ParticipantConnectionEnum
}

export type MutationDeleteParticipantArgs = {
  ids: Array<Scalars['ID']>
}

export type MutationBulkUpdateParticipantArgs = {
  input: BulkUpdateParticipantInput
}

export type MutationCreatePracticeArgs = {
  input: PracticeCreateInput
}

export type MutationUpdatePracticeArgs = {
  id: Scalars['ID']
  input: PracticeUpdateInput
}

export type MutationDeletePracticeArgs = {
  ids: Array<Scalars['ID']>
}

export type MutationFakeVotesCounterArgs = {
  category: Scalars['ID']
}

export type MutationCreateVoteArgs = {
  input: VoteCreateInput
}

export type MutationOpenVotingArgs = {
  category: Scalars['ID']
}

export type MutationClearAllVotesArgs = {
  category: Scalars['ID']
  action: Scalars['String']
}

export type AwardSortInput = {
  name?: Maybe<Scalars['Int']>
  weight?: Maybe<Scalars['Int']>
}

export type AwardFilterInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  weight?: Maybe<Scalars['Int']>
}

export type AwardSearchInput = {
  q?: Maybe<Scalars['String']>
  page?: Maybe<Scalars['Int']>
  perPage?: Maybe<Scalars['Int']>
  sort?: Maybe<AwardSortInput>
  filter?: Maybe<AwardFilterInput>
}

export type AwardCreateInput = {
  name: Scalars['String']
  weight: Scalars['Int']
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type AwardUpdateInput = {
  name?: Maybe<Scalars['String']>
  weight?: Maybe<Scalars['Int']>
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type Award = {
  __typename?: 'Award'
  _id: Scalars['ID']
  name: Scalars['String']
  weight: Scalars['Int']
  categories: Array<Maybe<Category>>
  createdAt: CustomDate
  updatedAt: CustomDate
}

export type AwardEdge = {
  __typename?: 'AwardEdge'
  edges: Array<Maybe<Award>>
  pagination?: Maybe<Pagination>
}

export type AwardResponse = {
  __typename?: 'AwardResponse'
  message?: Maybe<Scalars['String']>
  data: Award
}

export type AwardOnChange = {
  __typename?: 'AwardOnChange'
  _id: Scalars['ID']
  action: ActionsEnum
  data: Award
}

export type Query = {
  __typename?: 'Query'
  award?: Maybe<Award>
  awards: AwardEdge
  category?: Maybe<Category>
  categories: CategoryEdge
  configurationMap: ConfigurationMap
  configurations: Array<Maybe<ConfigurationUnion>>
  publicConfigurations: PublicConfiguration
  message?: Maybe<Message>
  messages: MessageEdge
  participant?: Maybe<Participant>
  participants: ParticipantEdge
  participantMe?: Maybe<Participant>
  practice?: Maybe<Practice>
  practices: PracticeEdge
  votesCounter: VoteCounter
  votesResult: VoteResultCategory
  myVotes?: Maybe<VoteBallot>
  api: VersionInfo
  sampleError: VersionInfo
}

export type QueryAwardArgs = {
  id: Scalars['ID']
}

export type QueryAwardsArgs = {
  params?: Maybe<AwardSearchInput>
}

export type QueryCategoryArgs = {
  id: Scalars['ID']
}

export type QueryCategoriesArgs = {
  params?: Maybe<CategorySearchInput>
}

export type QueryConfigurationsArgs = {
  params?: Maybe<ConfigurationSearchInput>
}

export type QueryMessageArgs = {
  id: Scalars['ID']
}

export type QueryMessagesArgs = {
  params?: Maybe<MessageSearchInput>
}

export type QueryParticipantArgs = {
  id: Scalars['ID']
}

export type QueryParticipantsArgs = {
  params?: Maybe<ParticipantSearchInput>
}

export type QueryPracticeArgs = {
  id: Scalars['ID']
}

export type QueryPracticesArgs = {
  params?: Maybe<PracticeSearchInput>
}

export type QueryVotesCounterArgs = {
  category: Scalars['ID']
}

export type QueryVotesResultArgs = {
  category: Scalars['ID']
}

export type QueryMyVotesArgs = {
  category: Scalars['ID']
}

export type Subscription = {
  __typename?: 'Subscription'
  awardOnChanges: AwardOnChange
  categoryOnChanges: CategoryOnChange
  configurationMapOnChanges: ConfigurationMapOnChanges
  configurationOnChanges: ConfigurationOnChange
  messageOnChanges: MessageOnChange
  participantOnChanges: ParticipantOnChange
  participantMeOnChange: ParticipantOnChange
  practiceOnChanges: PracticeOnChange
  votesCounterOnChanges: VoteCounter
}

export type SubscriptionParticipantOnChangesArgs = {
  params?: Maybe<ParticipantSearchInput>
}

export type SubscriptionVotesCounterOnChangesArgs = {
  category: Scalars['ID']
}

export enum CategoryStatusEnum {
  /** Categoria ainda vou votada */
  Pendent = 'PENDENT',
  /** Em votação */
  Voting = 'VOTING',
  /** Após fechar a votação de todas as praticas da categoria */
  Voted = 'VOTED'
}

export type CategorySearchInput = {
  q?: Maybe<Scalars['String']>
  page?: Maybe<Scalars['Int']>
  perPage?: Maybe<Scalars['Int']>
  sort?: Maybe<CategorySortInput>
  filter?: Maybe<CategoryFilterInput>
}

export type CategorySortInput = {
  name?: Maybe<Scalars['Int']>
  number?: Maybe<Scalars['Int']>
  quantityWin?: Maybe<Scalars['Int']>
  status?: Maybe<Scalars['Int']>
}

export type CategoryFilterInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['Int']>
  quantityWin?: Maybe<Scalars['Int']>
  status?: Maybe<CategoryStatusEnum>
}

export type CategoryCreateInput = {
  name: Scalars['String']
  number: Scalars['Int']
  quantityWin: Scalars['Int']
}

export type CategoryUpdateInput = {
  name?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['Int']>
  quantityWin?: Maybe<Scalars['Int']>
}

export type Category = {
  __typename?: 'Category'
  _id: Scalars['ID']
  name: Scalars['String']
  number: Scalars['Int']
  quantityWin: Scalars['Int']
  status: CategoryStatusEnum
  createdAt: CustomDate
  updatedAt: CustomDate
  awards: Array<Maybe<Award>>
  practices: Array<Maybe<Practice>>
}

export type CategoryEdge = {
  __typename?: 'CategoryEdge'
  edges: Array<Maybe<Category>>
  pagination?: Maybe<Pagination>
}

export type CategoryResponse = {
  __typename?: 'CategoryResponse'
  message?: Maybe<Scalars['String']>
  data: Category
}

export type CategoryOnChange = {
  __typename?: 'CategoryOnChange'
  _id: Scalars['ID']
  action: ActionsEnum
  data: Category
}

export enum ConfigurationKeyEnum {
  Screen = 'SCREEN',
  Appearance = 'APPEARANCE',
  SignUp = 'SIGN_UP',
  Streaming = 'STREAMING',
  Message = 'MESSAGE'
}

export enum ConfigurationSignUpAuthTypeEnum {
  /** Participantes se cadastrem sozinhos e não precisa aguardar aprovação */
  Public = 'PUBLIC',
  /** Participantes se cadastrem sozinhos porém deverão aguardar na sala de espera a liberação. */
  Private = 'PRIVATE',
  /** Participantes não pode se cadastrar sozinhos, acesso apenas a users previamente cadastrados pelo ADMIN */
  Restricted = 'RESTRICTED'
}

export enum ConfigurationScreenTypeEnum {
  /** Tela vazia, logo centralizado */
  Empty = 'EMPTY',
  /** Tela de votação */
  Voting = 'VOTING',
  /** Tela de capa RESULTADO categoria, exibição do title e descrição */
  Cover = 'COVER',
  /** Tela de RESULTADO, exibição do total de votos em uma pratica */
  Result = 'RESULT',
  /** Exibição de uma mensagem */
  Message = 'MESSAGE'
}

export type ConfigurationSearchInput = {
  keys?: Maybe<Array<Maybe<ConfigurationKeyEnum>>>
}

export type ConfigurationAppearanceStyleFontInput = {
  fontSize: Scalars['String']
  fontFamily: Scalars['String']
  fontAlign: Scalars['String']
}

export type ConfigurationAppearanceStyleColorsInput = {
  primary: Scalars['String']
  secondary: Scalars['String']
  textColor: Scalars['String']
  backgroundColor: Scalars['String']
}

export type ConfigurationAppearanceAssetsInput = {
  backgroundImage?: Maybe<Scalars['Upload']>
  logo?: Maybe<Scalars['Upload']>
}

export type ConfigurationAppearanceStyleInput = {
  font: ConfigurationAppearanceStyleFontInput
  colors: ConfigurationAppearanceStyleColorsInput
}

export type ConfigurationMessageInput = {
  title: Scalars['String']
  content: Scalars['String']
}

export type ConfigurationStreamingInput = {
  enable: Scalars['Boolean']
  iframe?: Maybe<Scalars['String']>
  embed?: Maybe<Scalars['String']>
}

export type ConfigurationScreenOptionsInput = {
  category?: Maybe<Scalars['ID']>
}

export type MultiUploadInput = {
  color?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['Upload']>
  background?: Maybe<Scalars['Upload']>
}

export type Configuration = {
  _id: Scalars['ID']
  key: ConfigurationKeyEnum
  createdAt: CustomDate
  updatedAt: CustomDate
}

export type ConfigurationUnion =
  | ConfigurationAppearance
  | ConfigurationSignUp
  | ConfigurationStreaming
  | ConfigurationMessage
  | ConfigurationScreen

export type ConfigurationScreen = Configuration & {
  __typename?: 'ConfigurationScreen'
  _id: Scalars['ID']
  key: ConfigurationKeyEnum
  screenType: ConfigurationScreenTypeEnum
  category?: Maybe<Category>
  results?: Maybe<Array<Maybe<Practice>>>
  start: Scalars['Boolean']
  createdAt: CustomDate
  updatedAt: CustomDate
}

export type ConfigurationMessage = Configuration & {
  __typename?: 'ConfigurationMessage'
  _id: Scalars['ID']
  key: ConfigurationKeyEnum
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  createdAt: CustomDate
  updatedAt: CustomDate
}

export type ConfigurationAppearanceStyleFont = {
  __typename?: 'ConfigurationAppearanceStyleFont'
  fontSize: Scalars['String']
  fontFamily: Scalars['String']
  fontAlign: Scalars['String']
}

export type ConfigurationAppearanceStyleColors = {
  __typename?: 'ConfigurationAppearanceStyleColors'
  primary: Scalars['String']
  secondary: Scalars['String']
  textColor: Scalars['String']
  backgroundColor: Scalars['String']
}

export type ConfigurationAppearanceStyle = {
  __typename?: 'ConfigurationAppearanceStyle'
  font?: Maybe<ConfigurationAppearanceStyleFont>
  colors?: Maybe<ConfigurationAppearanceStyleColors>
}

export type ConfigurationAppearanceAssets = {
  __typename?: 'ConfigurationAppearanceAssets'
  backgroundImage?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
}

export type ConfigurationAppearance = Configuration & {
  __typename?: 'ConfigurationAppearance'
  _id: Scalars['ID']
  key: ConfigurationKeyEnum
  assets?: Maybe<ConfigurationAppearanceAssets>
  globalStyle?: Maybe<ConfigurationAppearanceStyle>
  practiceStyle?: Maybe<ConfigurationAppearanceStyle>
  categoryStyle?: Maybe<ConfigurationAppearanceStyle>
  createdAt: CustomDate
  updatedAt: CustomDate
}

export type ConfigurationStreaming = Configuration & {
  __typename?: 'ConfigurationStreaming'
  _id: Scalars['ID']
  key: ConfigurationKeyEnum
  enable: Scalars['Boolean']
  iframe?: Maybe<Scalars['String']>
  embed?: Maybe<Scalars['String']>
  createdAt: CustomDate
  updatedAt: CustomDate
}

export type ConfigurationSignUp = Configuration & {
  __typename?: 'ConfigurationSignUp'
  _id: Scalars['ID']
  key: ConfigurationKeyEnum
  authType: ConfigurationSignUpAuthTypeEnum
  createdAt: CustomDate
  updatedAt: CustomDate
}

export type ConfigurationMap = {
  __typename?: 'ConfigurationMap'
  /** Aparência: determina como os participantes verão a interface gráfica do sistema. */
  appearance?: Maybe<ConfigurationAppearance>
  /** Na tela de configuração no ADMIN, deve conter configuração para determinar se será permitido: */
  signUp?: Maybe<ConfigurationSignUp>
  /** Deve ser possível inserir um LINK ou um HTML que será o iframe de comunicação a esquerda da tela do votante. */
  streaming?: Maybe<ConfigurationStreaming>
  /** Uma mensagem é uma tela que se sobrepõe ao que está sendo exibido ao participante. */
  messageCurrent?: Maybe<ConfigurationMessage>
  screen?: Maybe<ConfigurationScreen>
}

export type ConfigurationResponse = {
  __typename?: 'ConfigurationResponse'
  message?: Maybe<Scalars['String']>
  data: ConfigurationUnion
}

export type ConfigurationOnChange = {
  __typename?: 'ConfigurationOnChange'
  _id: Scalars['ID']
  action: ActionsEnum
  data: ConfigurationUnion
}

export type ConfigurationMapOnChanges = {
  __typename?: 'ConfigurationMapOnChanges'
  key: ConfigurationKeyEnum
  data: ConfigurationMap
}

export type PublicConfiguration = {
  __typename?: 'PublicConfiguration'
  authType: ConfigurationSignUpAuthTypeEnum
}

export type MessageSortInput = {
  title?: Maybe<Scalars['Int']>
}

export type MessageFilterInput = {
  id?: Maybe<Scalars['ID']>
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
}

export type MessageSearchInput = {
  q?: Maybe<Scalars['String']>
  page?: Maybe<Scalars['Int']>
  perPage?: Maybe<Scalars['Int']>
  sort?: Maybe<MessageSortInput>
  filter?: Maybe<MessageFilterInput>
}

export type MessageCreateInput = {
  title: Scalars['String']
  content: Scalars['String']
}

export type MessageUpdateInput = {
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
}

export type Message = {
  __typename?: 'Message'
  _id: Scalars['ID']
  title: Scalars['String']
  content: Scalars['String']
  createdAt: CustomDate
  updatedAt: CustomDate
}

export type MessageEdge = {
  __typename?: 'MessageEdge'
  edges: Array<Maybe<Message>>
  pagination?: Maybe<Pagination>
}

export type MessageResponse = {
  __typename?: 'MessageResponse'
  message?: Maybe<Scalars['String']>
  data: Message
}

export type MessageOnChange = {
  __typename?: 'MessageOnChange'
  _id: Scalars['ID']
  action: ActionsEnum
  data: Message
}

export enum ParticipantActionEnum {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Deleted = 'DELETED',
  Voted = 'VOTED',
  Connection = 'CONNECTION'
}

export enum ParticipantRoleEnum {
  Voter = 'VOTER',
  God = 'GOD',
  Viewer = 'VIEWER'
}
export enum ParticipantAVEnum {
  On = 'ON',
  Off = 'OFF',
  Pending = 'PENDING',
  Ignored = 'IGNORED'
}

export enum ParticipantStatusEnum {
  /** Quando uma authType=PRIVATE e ele se cadastra fica como pendente para aprovar */
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Blocked = 'BLOCKED'
}

export enum ParticipantConnectionEnum {
  Online = 'ONLINE',
  Offline = 'OFFLINE',
  Away = 'AWAY'
}

export enum ParticipantBulkActionEnum {
  SetPending = 'SET_PENDING',
  SetBlocked = 'SET_BLOCKED',
  SetApproved = 'SET_APPROVED',
  Logout = 'LOGOUT'
}

export type ParticipantSortInput = {
  name?: Maybe<Scalars['Int']>
  keypad?: Maybe<Scalars['Int']>
  login?: Maybe<Scalars['Int']>
  role?: Maybe<Scalars['Int']>
  status?: Maybe<Scalars['Int']>
  connection?: Maybe<Scalars['Int']>
}

export type ParticipantFilterInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  keypad?: Maybe<Scalars['String']>
  login?: Maybe<Scalars['String']>
  role?: Maybe<ParticipantRoleEnum>
  status?: Maybe<ParticipantStatusEnum>
  connection?: Maybe<ParticipantConnectionEnum>
}

export type ParticipantSearchInput = {
  q?: Maybe<Scalars['String']>
  page?: Maybe<Scalars['Int']>
  perPage?: Maybe<Scalars['Int']>
  sort?: Maybe<ParticipantSortInput>
  filter?: Maybe<ParticipantFilterInput>
}

export type ParticipantCreateInput = {
  name: Scalars['String']
  role: ParticipantRoleEnum
  keypad: Scalars['String']
  login: Scalars['String']
  password: Scalars['String']
}

export type ParticipantUpdateInput = {
  name?: Maybe<Scalars['String']>
  role?: Maybe<ParticipantRoleEnum>
  keypad?: Maybe<Scalars['String']>
  login?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
}

export type BulkUpdateParticipantInput = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>
  all: Scalars['Boolean']
  action: ParticipantBulkActionEnum
}

export type ParticipantLastActivity = {
  __typename?: 'ParticipantLastActivity'
  ip: Scalars['String']
  date: CustomDate
}

export type Participant = {
  __typename?: 'Participant'
  _id: Scalars['ID']
  name: Scalars['String']
  role: ParticipantRoleEnum
  status: ParticipantStatusEnum
  connection: ParticipantConnectionEnum
  keypad: Scalars['String']
  login: Scalars['String']
  lastActivity?: Maybe<ParticipantLastActivity>
  createdAt: CustomDate
  updatedAt: CustomDate
  votedPractices?: Maybe<Array<Maybe<Scalars['String']>>>
  av: ParticipantAVEnum
}

export type ParticipantVotedPracticesArgs = {
  category?: Maybe<Scalars['ID']>
}

export type ParticipantEdge = {
  __typename?: 'ParticipantEdge'
  edges: Array<Maybe<Participant>>
  pagination?: Maybe<Pagination>
}

export type ParticipantResponse = {
  __typename?: 'ParticipantResponse'
  message?: Maybe<Scalars['String']>
  data: Participant
}

export type ParticipantOnChange = {
  __typename?: 'ParticipantOnChange'
  _id: Scalars['ID']
  action: ParticipantActionEnum
  data: Participant
}

export enum PracticeStatusEnum {
  /** Pratica ainda vou votada */
  Pendent = 'PENDENT',
  /** Em votação */
  Voting = 'VOTING',
  /** Após fechar concluir a votação da pratica */
  Voted = 'VOTED'
}

export type PracticeSortInput = {
  name?: Maybe<Scalars['Int']>
  number?: Maybe<Scalars['Int']>
  category?: Maybe<Scalars['Int']>
  status?: Maybe<Scalars['Int']>
}

export type PracticeFilterInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['Int']>
  category?: Maybe<Scalars['ID']>
  status?: Maybe<PracticeStatusEnum>
}

export type PracticeSearchInput = {
  q?: Maybe<Scalars['String']>
  page?: Maybe<Scalars['Int']>
  perPage?: Maybe<Scalars['Int']>
  sort?: Maybe<PracticeSortInput>
  filter?: Maybe<PracticeFilterInput>
}

export type PracticeCreateInput = {
  name: Scalars['String']
  number: Scalars['Int']
  category: Scalars['ID']
}

export type PracticeUpdateInput = {
  name?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['Int']>
  category?: Maybe<Scalars['ID']>
}

export type Practice = {
  __typename?: 'Practice'
  _id: Scalars['ID']
  name: Scalars['String']
  number: Scalars['Int']
  status: PracticeStatusEnum
  category: Category
  createdAt: CustomDate
  updatedAt: CustomDate
}

export type PracticeEdge = {
  __typename?: 'PracticeEdge'
  edges: Array<Maybe<Practice>>
  pagination?: Maybe<Pagination>
}

export type PracticeResponse = {
  __typename?: 'PracticeResponse'
  message?: Maybe<Scalars['String']>
  data: Practice
}

export type PracticeOnChange = {
  __typename?: 'PracticeOnChange'
  _id: Scalars['ID']
  action: ActionsEnum
  data: Practice
}

export type VoteResultCategory = {
  __typename?: 'VoteResultCategory'
  category: Category
  practices: Array<Maybe<Practice>>
}

export type VoteCounter = {
  __typename?: 'VoteCounter'
  /** Quantidade votos */
  count: Scalars['Int']
  /** Numero de participantes APPROVED de todas as ROLES */
  participants: Scalars['Int']
  /** Numero de participantes APPROVED do tipo VOTER */
  voter: Scalars['Int']
  /** Numero de participantes APPROVED do tipo VIEWER */
  viewer: Scalars['Int']
  /** Numero de participantes que já votaram */
  ballots: Scalars['Int']
  /** Percentual */
  progress: Scalars['Float']
}

export type VotePracticeCreateInput = {
  practice: Scalars['ID']
  award: Scalars['ID']
}

export type VoteCreateInput = {
  category: Scalars['ID']
  votes: Array<Maybe<VotePracticeCreateInput>>
}

export type VoteBallotPractice = {
  __typename?: 'VoteBallotPractice'
  practice?: Maybe<Scalars['ID']>
  award: Scalars['ID']
}

export type VoteBallot = {
  __typename?: 'VoteBallot'
  ballot: Scalars['String']
  category: Scalars['ID']
  votes: Array<Maybe<VoteBallotPractice>>
}

export type Vote = {
  __typename?: 'Vote'
  _id: Scalars['ID']
  award: Award
  participant: Participant
  practice?: Maybe<Practice>
  ip: Scalars['String']
  weight: Scalars['Int']
  createdAt: CustomDate
  updatedAt: CustomDate
}

export type VoteResponse = {
  __typename?: 'VoteResponse'
  message?: Maybe<Scalars['String']>
  data: VoteBallot
}

export type VersionInfo = {
  __typename?: 'VersionInfo'
  name: Scalars['String']
  version: Scalars['String']
  env: Scalars['String']
  date: Scalars['Date']
  a?: Maybe<Scalars['String']>
}
