import React, { useContext } from 'react'
import { ConfigContext } from '../providers'
import { LogoBox } from './styles'

const Logo: React.FC = () => {
  const configContext = useContext(ConfigContext)

  return (
    <LogoBox>
      {configContext.appearance?.assets?.logo && (
        <img
          src={configContext.appearance?.assets?.logo}
          alt="Instituto Innovare Votação"
        />
      )}
    </LogoBox>
  )
}

export default Logo
