import React, { InputHTMLAttributes } from 'react'
import { InputContainer } from './styles'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  error?: boolean
  helperText?: string
}

const InputField: React.FC<InputProps> = ({
  name,
  label,
  error,
  helperText,
  ...rest
}) => {
  return (
    <InputContainer error={error}>
      <label htmlFor={name}>{label}</label>
      <input id={name} name={name} {...rest} />
      {helperText && <p>{helperText}</p>}
    </InputContainer>
  )
}

export default InputField
