import React from 'react'
import { MdSwapVerticalCircle } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

export const ChangeModeBtnStyled = styled.button`
  background-color: transparent;
  width: 100px;

  color: ${({ theme }) => theme.globalStyle.colors.textColor};
  font-size: 18px;

  padding: 14px;
  border-radius: 5px;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -62px;
  right: 165px;

  &:hover {
    background-color: ${({ theme }) => theme.globalStyle.colors.secondary};
  }
`

interface IProps {
  show: boolean
}
const ChangeModeBtn: React.FC<IProps> = ({ show }) => {
  const history = useHistory()

  const handleMode = () => {
    history.push('/selecionar-modo')
  }

  return (
    <div>
      {show && (
        <ChangeModeBtnStyled type="button" onClick={handleMode}>
          <MdSwapVerticalCircle size={'1.35em'} />
        </ChangeModeBtnStyled>
      )}
    </div>
  )
}
export default ChangeModeBtn
