import React from 'react'
import { Card, CategoryTitle, ExpandContainer, HeaderActions } from './styles'
import { Category } from '../../types'
import { FaArrowsAltH } from 'react-icons/fa'

type Props = {
  category: Category
  collapsed?: boolean
  collapse(): void
  hideCollapseBtn?: boolean
}

const ResultCover: React.FC<Props> = ({
  category,
  collapsed,
  collapse,
  hideCollapseBtn
}) => {
  return (
    <Card color="#E9E9E9" collapsed={collapsed}>
      {!hideCollapseBtn && (
        <HeaderActions>
          <ExpandContainer onClick={collapse}>
            <FaArrowsAltH />
          </ExpandContainer>
        </HeaderActions>
      )}
      <CategoryTitle>{category.name}</CategoryTitle>
    </Card>
  )
}

export default ResultCover
