import React, { useEffect } from 'react'

interface FocusHandlerProps {
  onBlur: () => void
  onFocus: () => void
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WindowFocusHandler = ({ onBlur, onFocus }: FocusHandlerProps) => {
  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  })

  return <></>
}

export default WindowFocusHandler
