import React, { useCallback } from 'react'
import { Redirect, useHistory, withRouter } from 'react-router-dom'

import { useKeycloak } from '@react-keycloak/web'

import { ContainerColumnCenter100vh, Layout, TitleSM } from '../components'
import { ButtonOutline, ButtonSolid } from '../components/Buttons/styles'
import { useQuery } from '@apollo/client'
import { PUBLIC_CONF } from '../components/auth/publicConf'
import {
  ConfigurationSignUpAuthTypeEnum,
  PublicConfiguration
} from '../types/graphql'

const LoginPage = withRouter(({ location }): any => {
  const history = useHistory()

  const currentLocationState = (location.state as {
    [key: string]: unknown
  }) || {
    from: { pathname: '/vote' }
  }
  const { keycloak } = useKeycloak()

  const login = useCallback(() => {
    return keycloak?.login()
  }, [keycloak])

  if (keycloak?.authenticated) {
    return <Redirect to={currentLocationState?.from as string} />
  }

  const { data } = useQuery<{
    publicConfigurations: PublicConfiguration
  }>(PUBLIC_CONF)

  const SignIn = () => (
    <ButtonSolid type="button" onClick={login}>
      Já tenho uma conta
    </ButtonSolid>
  )

  const SignUp = () => (
    <ButtonOutline onClick={() => history.push('/cadastro')}>
      Cadastre-se
    </ButtonOutline>
  )

  if (
    data?.publicConfigurations.authType ===
    ConfigurationSignUpAuthTypeEnum.Restricted
  ) {
    login()
  }

  const handleAuthType = () => {
    switch (data?.publicConfigurations.authType) {
      case ConfigurationSignUpAuthTypeEnum.Restricted:
        return <SignIn />
      default:
        return (
          <>
            <SignIn />
            <SignUp />
          </>
        )
    }
  }

  return (
    <Layout>
      <ContainerColumnCenter100vh>
        <TitleSM>Prêmio Innovare</TitleSM>
        <div>{handleAuthType()}</div>
      </ContainerColumnCenter100vh>
    </Layout>
  )
})

export default LoginPage
