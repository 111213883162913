import React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useHistory } from 'react-router-dom'

import { ReactComponent as SvgTop } from '../assets/top-vector.svg'
import { ReactComponent as SvgBottom } from '../assets/bottom-vector.svg'
import { styled } from '../../styles/theme'
import Logo from '../Logo'
import { MdPowerSettingsNew } from 'react-icons/md'
import { Header, LayoutContainer } from './styles'
import { LogoutBtn } from '../index'

const TopVector = styled(SvgTop)`
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`
const BottomVector = styled(SvgBottom)`
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`

const Layout: React.FC = ({ children }) => {
  const { keycloak } = useKeycloak()
  const history = useHistory()

  const handleLogout = () => {
    history.push('/')
    if (keycloak) {
      return keycloak?.logout()
    }
  }

  return (
    <LayoutContainer>
      <TopVector />
      <Header>
        <Logo />
        {keycloak?.authenticated && (
          <LogoutBtn type="button" onClick={handleLogout}>
            <MdPowerSettingsNew size={'1.35em'} />
            <span>Sair</span>
          </LogoutBtn>
        )}
      </Header>
      {children}
      <BottomVector />
    </LayoutContainer>
  )
}

export default Layout
