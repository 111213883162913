import React, { useEffect } from 'react'
import { VoteStatusClosed, VoteStatusOpened, VoteStatusPending } from './styles'
import { MdPauseCircleOutline, MdSchedule, MdThumbUp } from 'react-icons/md'
import {
  Category,
  CategoryStatusEnum,
  Participant,
  VoteCounter
} from '../../types'
import { useQuery } from '@apollo/client'
import { VOTE_COUNTER, COUNTER_SUBSCRIPTION } from './graphql'
import { toast } from 'react-toastify'

type Props = {
  category: Category
  user?: Participant
}
const VoteCardStatusLabel: React.FC<Props> = ({ category }) => {
  const { data, subscribeToMore, refetch } = useQuery<{
    votesCounter: VoteCounter
    votesCounterOnChanges?: VoteCounter
  }>(VOTE_COUNTER, {
    variables: {
      category: category._id
    }
  })

  useEffect(() => {
    if (category) {
      refetch()
    }
    subscribeToMore({
      document: COUNTER_SUBSCRIPTION,
      variables: {
        category: category._id
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev
        return Object.assign({
          votesCounter: subscriptionData.data.votesCounterOnChanges
        })
      },
      onError: error => {
        toast.error(error.message)
      }
    })
  }, [category, subscribeToMore, refetch])

  const votesPercentage = () => {
    if (data?.votesCounter) {
      return (
        <span>
          {data.votesCounter.ballots}/{data.votesCounter.voter}
        </span>
      )
    }
  }

  switch (category.status) {
    case CategoryStatusEnum.Voting:
      return (
        <VoteStatusOpened>
          <MdThumbUp size={'1.3em'} />
          <span>
            Votos
            {votesPercentage() && ' - '}
            {votesPercentage()}
          </span>
        </VoteStatusOpened>
      )
    case CategoryStatusEnum.Voted:
      return (
        <VoteStatusClosed>
          <MdSchedule size={'1.3em'} />
          <span>Tempo Esgotado</span>
        </VoteStatusClosed>
      )
    case CategoryStatusEnum.Pendent:
    default:
      return (
        <VoteStatusPending>
          <MdPauseCircleOutline size={'1.3em'} />
          <span>Aguarde</span>
        </VoteStatusPending>
      )
  }
}
export default VoteCardStatusLabel
