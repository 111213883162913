/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import { ButtonSolid } from '../Buttons/styles'
import {
  Card,
  CategoryTitle,
  ContainerOptions,
  HeaderSection,
  VotingSection,
  VoteOption,
  VoteCheckbox,
  VoteLabel,
  PracticeTitle,
  ExpandContainer
} from './styles'
import {
  Category,
  CategoryStatusEnum,
  Participant,
  ParticipantRoleEnum,
  Practice,
  VoteCreateInput,
  VotePracticeCreateInput
} from '../../types'
import { VoteCardStatusLabel } from '../index'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import './confirm.css'
import { useMutation, useQuery } from '@apollo/client'
import { ADD_VOTES, ADD_VOTES_GOD, MY_VOTES } from './graphql'
import { toast } from 'react-toastify'
import { FaArrowsAltH, FaVoteYea } from 'react-icons/fa'
import { Maybe } from 'graphql/jsutils/Maybe'
import SelectedVoterCard from './SelectedVoterCard'

type Props = {
  category: Category
  participant?: Participant
  collapse(): void
  collapsed: boolean
  hideCollapseBtn?: boolean
  voter?: Participant
}

const VotingCard: React.FC<Props> = ({
  category,
  participant,
  collapse,
  collapsed,
  hideCollapseBtn,
  voter
}) => {
  const { practices, awards, _id } = category
  const [addVote, { loading }] = useMutation(ADD_VOTES)
  const [addVoteGood, { loading: loadingGod }] = useMutation(ADD_VOTES_GOD)
  const {
    data: myVotes,
    loading: myVotesLoading,
    refetch
  } = useQuery(MY_VOTES, {
    variables: {
      category: _id
    }
  })
  const [voted, setVoted] = useState<boolean>(false)
  const [voteCreateInpupt, setVoteCreateInput] = useState<VoteCreateInput>({
    category: _id,
    votes: []
  })

  useEffect(() => {
    // console.log('My Votes: ', myVotes)

    if (myVotes?.myVotes && participant?.role === ParticipantRoleEnum.Voter) {
      setVoteCreateInput({
        category: myVotes.myVotes.category,
        votes: myVotes.myVotes.votes
      })
      setVoted(true)
    } else {
      setVoteCreateInput({
        category: _id,
        votes: []
      })
      setVoted(false)
    }
  }, [myVotes, _id])

  useEffect(() => {
    refetch()
    setVoteCreateInput(voteCreateInpupt => ({
      ...voteCreateInpupt,
      category: _id
    }))
  }, [_id, refetch])

  const getColorCard = () => {
    switch (category.status) {
      case CategoryStatusEnum.Voting:
        return '#d3efdf'
      case CategoryStatusEnum.Voted:
        return '#fcd2d3'
      case CategoryStatusEnum.Pendent:
      default:
        return '#fff'
    }
  }

  function getVote(vote: VotePracticeCreateInput) {
    if (
      voteCreateInpupt.votes.find(
        v => v?.award === vote.award && v.practice === vote.practice
      )
    ) {
      return setVoteCreateInput(voteCreateInpupt => ({
        ...voteCreateInpupt,
        votes: [
          ...voteCreateInpupt.votes.filter(v => v?.practice !== vote.practice)
        ]
      }))
    } else if (voteCreateInpupt.votes.find(v => v?.award === vote.award)) {
      return setVoteCreateInput(voteCreateInpupt => ({
        ...voteCreateInpupt,
        votes: [
          ...voteCreateInpupt.votes
            .filter(v => v?.award !== vote.award)
            .filter(v => v?.practice !== vote.practice),
          vote
        ]
      }))
    } else if (
      voteCreateInpupt.votes.find(v => v?.practice === vote.practice)
    ) {
      return setVoteCreateInput(voteCreateInpupt => ({
        ...voteCreateInpupt,
        votes: [
          ...voteCreateInpupt.votes.filter(v => v?.practice !== vote.practice),
          vote
        ]
      }))
    } else {
      return setVoteCreateInput(voteCreateInpupt => ({
        ...voteCreateInpupt,
        votes: [...voteCreateInpupt.votes, vote]
      }))
    }
  }

  const generateAwards = (practice: Practice) => {
    return awards.map(award => {
      return (
        <VoteOption
          key={award?._id}
          isVoting={
            category.status === CategoryStatusEnum.Voting &&
            !myVotesLoading &&
            !loadingGod &&
            !voted &&
            participant &&
            (participant.role === ParticipantRoleEnum.Voter ||
              participant.role === ParticipantRoleEnum.God)
          }
        >
          <VoteCheckbox
            type="checkbox"
            id={`${practice._id}/${award?._id}`}
            name={award?.name}
            checked={
              !!voteCreateInpupt.votes.find(
                t => t?.award === award?._id && t?.practice === practice._id
              )
            }
            onChange={() => {
              if (award?._id && practice._id) {
                return getVote({
                  award: award?._id,
                  practice: practice._id
                })
              }
            }}
          />
          <VoteLabel htmlFor={`${practice._id}/${award?._id}`}>
            {award?.name}
          </VoteLabel>
        </VoteOption>
      )
    })
  }

  function truncate(str: string, n: number) {
    return str.length > n ? str.substr(0, n - 1) + '...' : str
  }

  function practiceNumber(_id: string | undefined) {
    const practice = practices.find(p => p?._id === _id)
    if (practice) {
      return practice.number
    }
  }

  function practiceOrEmpty(vote: Maybe<VotePracticeCreateInput>[]) {
    if (vote[0] === null || vote[0] === undefined) {
      return 'EM BRANCO'
    }
    return practiceNumber(vote[0].practice)
  }

  const successDialog = (data: any) => {
    resetAwards()

    confirmAlert({
      // eslint-disable-next-line react/display-name
      customUI: ({ onClose }) => {
        setTimeout(onClose, 5000)
        return (
          <div
            className="custom-ui"
            style={{
              position: 'relative',
              width: '30vw',
              backgroundColor: '#27ae60',
              borderRadius: 5,
              padding: '20px',
              color: 'white',
              textAlign: 'center'
            }}
          >
            <h2>VOTO REGISTRADO COM SUCESSO!</h2>
            <br />
            <FaVoteYea style={{ fontSize: '2rem' }} />
            <br />
            <p>Aguarde, está mensagem fechará automaticamente.</p>
            <br />
            <h6>
              Comprovante:{' '}
              {data.createVote?.data.ballot ||
                data.createVoteModeGod?.data.ballot}
            </h6>
            <br />
            <button
              className="btn-cancel"
              onClick={() => onClose()}
              style={{ width: '100%' }}
            >
              FECHAR
            </button>
            <div className="bar">
              <div className="in"> </div>
            </div>
          </div>
        )
      }
    })
  }

  const handleVoteDialog = () => {
    confirmAlert({
      // eslint-disable-next-line react/display-name
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui"
            style={{
              width: '30vw',
              backgroundColor: '#fff',
              border: '1px solid #333',
              borderRadius: 5,
              padding: '20px'
            }}
          >
            <h1>Confirmar votos ?</h1>
            <br />
            <p>Esta ação não poderá ser desfeita.</p>
            <br />
            {voter && (
              <>
                <p>Voê está votando por:</p>
                <SelectedVoterCard participant={voter} />
                <br />
              </>
            )}
            {awards.map(a => {
              return (
                <>
                  <h2 key={a?._id} className="award">
                    {a?.name}:{' '}
                    <span className="practice">
                      {practiceOrEmpty(
                        voteCreateInpupt.votes.filter(v => v?.award === a?._id)
                      )}
                    </span>
                  </h2>
                  <br />
                </>
              )
            })}
            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around'
              }}
            >
              <button className="btn-cancel" onClick={onClose}>
                CORRIGIR
              </button>
              <button
                className="btn-confirm"
                onClick={() => {
                  handleConfirm()
                  onClose()
                }}
              >
                CONFIRMAR VOTO
              </button>
            </div>
          </div>
        )
      }
    })
  }

  const SuccessMessage = ({ data }: any) => {
    if (data.createVoteModeGod) {
      return (
        <div>
          <h2>{data.createVoteModeGod.message}</h2>
          <br />
          <h6>Cédula: {data.createVoteModeGod.data.ballot}</h6>
        </div>
      )
    }

    if (data.createVote) {
      return (
        <div>
          <h2>{data.createVote.message}</h2>
          <br />
          <h6>Cédula: {data.createVote.data.ballot}</h6>
        </div>
      )
    }

    return <></>
  }

  const handleConfirm = () => {
    const successVote = (res: any) => {
      toast.success(<SuccessMessage data={res.data} />)
      successDialog(res.data)
      refetch()
    }

    const errorVote = (error: any) => {
      console.log('erro vote', error)
    }

    if (voter && participant?.role === ParticipantRoleEnum.God) {
      console.log('*** voted categorie', voteCreateInpupt.category)
      addVoteGood({
        variables: {
          input: voteCreateInpupt,
          participant: voter._id
        }
      }).then(successVote, errorVote)
    } else {
      addVote({
        variables: {
          input: voteCreateInpupt
        }
      }).then(successVote, errorVote)
    }
  }

  function getPraticeToShow(pratice: number | undefined) {
    let praticeString = ''
    praticeString = '' + pratice
    praticeString = praticeString.replace('-', '')
    praticeString = praticeString + ' - '
    return praticeString
  }
  function getList(originalList: Array<Maybe<Practice>>) {
    const newList = originalList.slice()
    if (
      newList.length > 0 &&
      newList[0] !== undefined &&
      newList[0]?.number !== undefined &&
      newList[0]?.number < 0
    ) {
      return newList.reverse()
    }
    return originalList
  }

  function resetAwards() {
    setVoteCreateInput({
      category: _id,
      votes: []
    })
  }

  return (
    <Card color={getColorCard()} collapsed={collapsed}>
      <HeaderSection>
        <div>
          {!hideCollapseBtn && (
            <ExpandContainer onClick={collapse}>
              <FaArrowsAltH />
            </ExpandContainer>
          )}
        </div>
        <CategoryTitle>{category.name}</CategoryTitle>
        <VoteCardStatusLabel category={category} user={participant} />
      </HeaderSection>

      <VotingSection>
        {getList(practices).map(practice => (
          <div key={practice?._id}>
            <PracticeTitle>
              {getPraticeToShow(practice?.number)}
              {practice?.name && truncate(practice.name, 35)}
            </PracticeTitle>
            <ContainerOptions>
              {practice && generateAwards(practice)}
            </ContainerOptions>
          </div>
        ))}
        <div
          style={{
            display: 'flex'
          }}
        >
          <ButtonSolid
            onClick={() => handleVoteDialog()}
            disabled={
              loading ||
              category.status !== CategoryStatusEnum.Voting ||
              myVotesLoading ||
              loadingGod ||
              voted ||
              (participant &&
                participant.role === ParticipantRoleEnum.Viewer) ||
              (participant &&
                participant.role === ParticipantRoleEnum.God &&
                !voter)
            }
          >
            Votar
          </ButtonSolid>
        </div>
      </VotingSection>
    </Card>
  )
}

export default VotingCard
