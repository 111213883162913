import { gql } from '@apollo/client'

export const PARTICIPANT_ME = gql`
  query ParticipantMe {
    participantMe {
      _id
      av
      name
      role
      status
      connection
      lastActivity {
        ip
        date {
          utc
        }
      }
    }
  }
`

export const SUBSCRIPTION_ME = gql`
  subscription ParticipantMe {
    participantMeOnChange {
      data {
        _id
        name
        role
        status
        av
        connection
        lastActivity {
          ip
          date {
            utc
          }
        }
      }
    }
  }
`

export const SET_PARTICIPANT_ME_AV = gql`
  mutation SetAvParticipantMe($av: ParticipantAvEnum!) {
    setAvParticipantMe(av: $av) {
      message
      data {
        _id
        name
        av
      }
    }
  }
`
